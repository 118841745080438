import SVG from "react-inlinesvg";
import styled from "styled-components";
import { renderer } from "../svgs/renderer";
import { computer, colors, dimensions } from "../svgs/other/computer.svg";

type ComputerProps = {
  href: string;
};

const Container = styled.div`
  margin-top: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Hover = styled.div`
  @keyframes comp-float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-12px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  animation: bs-float 3s ease-in-out infinite;
`;

export const Computer = ({ href }: ComputerProps) => {
  return (
    <Hover>
      <Container onClick={() => window.open(href)}>
        <SVG
          src={renderer({ colors, svg: computer, dimensions })}
          height={180}
          width={260}
          style={{ transform: "translate(0px, 10px)" }}
        />
      </Container>
    </Hover>
  );
};
