import SVG from "react-inlinesvg";
import { base } from "./base.svg";
import { renderer } from "./renderer";
import { BigNumber } from "ethers";
import { MgearGroup } from "../../../ChangingRoom/mgear-to-svg";

export type Svg = {
  z: number;
  colors: string[];
  svg: number[];
  rarity: number;
  augmented: boolean;
  mgear: BigNumber;
  id?: string;
  group?: MgearGroup;
};

type ComposedProps = {
  svgs: Svg[];
  height?: string;
  width?: string;
};

const compose = (svgs: Svg[]): string[] => {
  const composedSvg = [...base];
  const sortedByZ = svgs.sort((a, b) => a.z - b.z);

  console.log("z sorted", sortedByZ);

  sortedByZ.forEach((svg) => {
    const { colors } = svg;
    svg.svg.forEach((colorIndex, i) => {
      if (colorIndex !== 0) {
        composedSvg[i] = colors[colorIndex];
      }
    });
  });

  return composedSvg;
};

export const download = (s: Svg[]) => {
  var dl = document.createElement("a");
  document.body.appendChild(dl); // This line makes it work in Firefox.

  const rendered = renderer({
    svg: compose(s),
    dimensions: { x: 37, y: 26 },
  });

  const split = rendered.split("data:image/svg+xml;utf8,");
  const dataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(split[1])}`;

  dl.setAttribute("href", dataUrl);

  dl.setAttribute("download", "mgear_avatar.svg");
  dl.click();
};

export const Composed = ({ svgs, height = "444", width = "624" }: ComposedProps) => {
  return (
    <>
      <SVG
        src={renderer({
          svg: compose(svgs),
          dimensions: { x: 37, y: 26 },
        })}
        height={height}
        width={width}
        style={{ transform: "translate(0px, 10px)" }}
      />
    </>
  );
};
