import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { guyNoGear } from "../svgs/changing-room/guy";
import { Svg } from "./Composed";
import { BigNumber } from "ethers";
import { MGearControls, Item } from "./MGearControls";
import {
  CharacterControls,
  skinColors,
  hairColors,
  eyeColors,
  clothingColors,
  armColors,
} from "./CharacterControls";
import { Stats } from "./Stats";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  gap: 32px;
  justify-content: center;
  align-items: center;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const randomNumber = (max: number) => {
  const num = Math.floor(Math.random() * max);
  return num;
};

const guySvg = {
  svg: guyNoGear,
  z: 1,
  colors: [
    "none",
    hairColors[randomNumber(hairColors.length)],
    eyeColors[randomNumber(eyeColors.length)],
    skinColors[randomNumber(skinColors.length)],
    armColors[randomNumber(armColors.length)],
    clothingColors[randomNumber(clothingColors.length)],
  ],
  augmented: false,
  rarity: 0,
  mgear: BigNumber.from(0),
};

type ChangingRoomProps = {
  svgs: Svg[];
  setSvgs: Dispatch<SetStateAction<Svg[]>>;
};

type StatsType = { celerity: number; ruin: number; vigor: number; guard: number };

const getStats = (
  mgear: { mgear: BigNumber; name: string; attributes: { trait_type: string; value: string }[] }[]
): StatsType => {
  const stats = { celerity: 0, vigor: 0, ruin: 0, guard: 0 };

  mgear.forEach((m) => {
    const { attributes } = m;

    const celerity = attributes.find((a) => a.trait_type === "celerity");
    if (celerity !== undefined) stats.celerity = stats.celerity + parseInt(celerity.value);

    const ruin = attributes.find((a) => a.trait_type === "ruin");
    if (ruin !== undefined) stats.ruin = stats.ruin + parseInt(ruin.value);

    const guard = attributes.find((a) => a.trait_type === "guard");
    if (guard !== undefined) stats.guard = stats.guard + parseInt(guard.value);

    const vigor = attributes.find((a) => a.trait_type === "vigor");
    if (vigor !== undefined) stats.vigor = stats.vigor + parseInt(vigor.value);
  });

  return stats;
};

export const ChangingRoom = ({ svgs, setSvgs }: ChangingRoomProps) => {
  const [equipped, setEquipped] = useState<Item[]>([]);

  const updateGuy = (index: number) => (color: string) => {
    setSvgs((s: Svg[]) => {
      const updatedColors = s[0].colors;
      updatedColors[index] = color;
      s[0] = {
        ...guySvg,
        colors: updatedColors,
      };
      return [...s];
    });
  };

  return (
    <Center>
      <Column>
        <CharacterControls {...{ updateGuy }} />
        <Stats {...getStats(equipped)} />
        <MGearControls {...{ svgs, setSvgs, equipped, setEquipped }} />
      </Column>
    </Center>
  );
};
