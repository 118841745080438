import { BigNumber } from "ethers";
import styled from "styled-components";
import { MGear } from "../MGear";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import {
  getStats,
  getBonusMod,
  rarityIndexToRarityName,
  getRarityIndex,
  getIsAugmented,
  getMGearTypeName,
} from "../../utils/mgear";
import { useState, useEffect } from "react";
import { getMGearFromMpunk } from "../../web3/methods";

type MGearWithStatsMPunkProps = { mpunk: number };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MGearWithStatsMPunk = ({ mpunk }: MGearWithStatsMPunkProps) => {
  const [mgear, setMgear] = useState<BigNumber>(BigNumber.from(0));
  const { library } = useWeb3React<Web3Provider>();

  const stats = getStats(mgear);
  const statKeys = Object.keys(stats);

  useEffect(() => {
    const getMgear = async () => {
      if (library && mgear.toHexString() === "0x00") {
        const mgearFromMpunk = await getMGearFromMpunk(library, mpunk);
        setMgear(mgearFromMpunk);
      }
    };
    getMgear();
  }, [library, mpunk, mgear]);

  return !(mgear.toHexString() === "0x00") ? (
    <Container>
      {getBonusMod(mgear) && <div>BONUS GEAR ROLL</div>}
      {getIsAugmented(mgear) && <div>++AUGMENTED++</div>}
      <MGear mgear={mgear} renderOnChain />
      {rarityIndexToRarityName[getRarityIndex(mgear)]} {getMGearTypeName(mgear)}
      {statKeys.map((stat) => (
        <div>
          {stat}: +{stats[stat]}
        </div>
      ))}
      {statKeys.length === 0 && <div>No stats.</div>}
    </Container>
  ) : (
    <>loading...</>
  );
};
