/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IOnChainPixelArtv2,
  IOnChainPixelArtv2Interface,
} from "../IOnChainPixelArtv2";

const _abi = [
  {
    inputs: [
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "base64Encode",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "layer1",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "layer2",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "totalPixels",
        type: "uint256",
      },
    ],
    name: "composeLayers",
    outputs: [
      {
        internalType: "uint256[]",
        name: "comp",
        type: "uint256[]",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "palette1",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "palette2",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "colorCount1",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "colorCount2",
        type: "uint256",
      },
    ],
    name: "composePalettes",
    outputs: [
      {
        internalType: "uint256[]",
        name: "composedPalette",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "colors",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "pixelCompression",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "colorCount",
        type: "uint256",
      },
    ],
    name: "encodeColorArray",
    outputs: [
      {
        internalType: "uint256[]",
        name: "encoded",
        type: "uint256[]",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "layer",
        type: "uint256[]",
      },
    ],
    name: "getColorCount",
    outputs: [
      {
        internalType: "uint256",
        name: "colorCount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "canvas",
        type: "uint256[]",
      },
      {
        internalType: "uint256[]",
        name: "palette",
        type: "uint256[]",
      },
      {
        internalType: "uint256",
        name: "xDim",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "yDim",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "svgExtension",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "paddingX",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "paddingY",
        type: "uint256",
      },
    ],
    name: "render",
    outputs: [
      {
        internalType: "string",
        name: "svg",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "toHexString",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "toString",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "data",
        type: "string",
      },
    ],
    name: "uri",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "data",
        type: "string",
      },
    ],
    name: "uriSvg",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];

export class IOnChainPixelArtv2__factory {
  static readonly abi = _abi;
  static createInterface(): IOnChainPixelArtv2Interface {
    return new utils.Interface(_abi) as IOnChainPixelArtv2Interface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IOnChainPixelArtv2 {
    return new Contract(address, _abi, signerOrProvider) as IOnChainPixelArtv2;
  }
}
