//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const bow = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 4, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 4, 
    0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 2, 4, 
    0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 2, 5, 
    0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 2, 5, 
    0, 0, 0, 0, 0, 1, 0, 0, 0, 2, 0, 5, 
    0, 0, 0, 0, 1, 0, 0, 0, 2, 3, 0, 0, 
    0, 0, 0, 1, 0, 0, 0, 2, 3, 0, 0, 0, 
    0, 0, 1, 0, 0, 0, 2, 3, 0, 0, 0, 0, 
    0, 1, 0, 0, 0, 2, 3, 0, 0, 0, 0, 0, 
    1, 0, 2, 2, 2, 0, 0, 0, 0, 0, 0, 0, 
    4, 4, 4, 5, 5, 5, 0, 0, 0, 0, 0, 0,
]