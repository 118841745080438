import { BigNumber } from "ethers";
import styled from "styled-components";
import { HealthBar } from "../HealthBar";
import { Monster } from "../../Monster";
import { useMemo } from "react";

type CreatureProps = {
  seed: BigNumber;
  health: number;
  damageTaken: number;
  animationTrigger?: number;
};

const Hover = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  @keyframes comp-float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-12px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  animation: bs-float 3s ease-in-out infinite;
`;

export const Creature = ({ seed, health, damageTaken, animationTrigger }: CreatureProps) => {
  const memoizedAnimationTrigger = useMemo(() => animationTrigger, [animationTrigger]);
  return (
    <Hover>
      <HealthBar total={health} damageTaken={damageTaken} />
      <Monster nonce={seed} animationTrigger={memoizedAnimationTrigger} />
    </Hover>
  );
};
