import { createResponsiveGrid } from "../../../components/Grid";

type GridAreas = "character" | "inventory";

const positionAbsolute = {
  position: "absolute" as "absolute",
  transition: "all 0.5s ease-in-out",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const flexStyle = {
  display: "flex" as "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  alignItems: "center",
};

export const { Layout, Area } = createResponsiveGrid<GridAreas>({
  grids: [
    {
      template: {
        columns: ["1fr", "1fr"],
        rows: ["1fr"],
        areas: [["character", "inventory"]],
      },
      gridStyle: { fontSize: "16px", ...positionAbsolute, transition: "all 0.5s ease" },
    },
    {
      template: {
        columns: ["1fr"],
        rows: ["1fr", "1fr"],
        areas: [["character"], ["inventory"]],
      },
      gridStyle: { fontSize: "12px", ...positionAbsolute, transition: "all 0.5s ease" },
    },
  ],
  areaStyles: [
    {
      character: flexStyle,
      inventory: flexStyle,
    },
  ],
  cutoffs: [1224],
});
