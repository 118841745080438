import SVG from "react-inlinesvg";
import { BigNumber } from "ethers";
import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { mGearRenderer } from "../svgs/mgear-renderer";
import { rarityIndexToColor, getAdjustedRarity, encodeMGear } from "../../utils/mgear";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { renderMGear } from "../../web3/methods";

type MGearProps = {
  mgear: BigNumber;
  rarityIndex?: number;
  isMpunk?: boolean;
  renderOnChain?: boolean;
  animate?: boolean;
};

const Container = styled.div<{ animate: boolean }>`
  height: 100px;
  width: 100px;
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  ${(props) => (props.animate ? "animation: float 3s ease-in-out infinite;" : "")}
  display: flex;
  position: relative;
`;

const auraAnimate = (color: string) => keyframes`
  0% {
    box-shadow: 0px 0px 30px 20px rgba(255, 255, 255, 0.37);
    background-color: rgba(255, 255, 255, 0.37);
  }
  50% {
    box-shadow: 0px 0px 30px 30px ${color};
    background-color: ${color};
  }
  100% {
    box-shadow: 0px 0px 30px 20px rgba(255, 255, 255, 0.37);
    background-color: rgba(255, 255, 255, 0.37);
  }
`;

const Aura = styled.div<{ color: string }>`
  opacity: 0.3;
  height: 100px;
  width: 100px;

  border-radius: 50%;
  animation: ${(props) => auraAnimate(props.color)} 3s ease-in-out infinite;
`;

const Area = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MGear = ({ mgear, rarityIndex, renderOnChain, animate = true }: MGearProps) => {
  const { library } = useWeb3React<Web3Provider>();
  const [src, setSrc] = useState<string>("");
  const rarity = rarityIndex;

  useEffect(() => {
    const render = async () => {
      const adjustedMgear = encodeMGear(mgear, getAdjustedRarity(mgear, rarity));
      if (renderOnChain && library) {
        try {
          const rendered = await renderMGear(library, adjustedMgear);
          setSrc(rendered);
        } catch (e: any) {
          setSrc(mGearRenderer(adjustedMgear, rarity));
          console.log(`Failed to render on-chain: ${e.message}`);
        }
      } else {
        setSrc(mGearRenderer(adjustedMgear, rarity));
      }
    };
    render();
  }, [library, mgear, rarity, renderOnChain]);

  return (
    <Container animate={animate}>
      {animate && (
        <Area>
          <Aura color={rarityIndexToColor[getAdjustedRarity(mgear, rarity)]} />
        </Area>
      )}
      <Area>
        {src === "" ? (
          <div
            style={{
              fontSize: "12px",
              height: "80px",
              width: "80px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p>rendering on-chain...</p>
          </div>
        ) : (
          <SVG src={src} height={80} width={80} />
        )}
      </Area>
    </Container>
  );
};
