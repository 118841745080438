import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import Typist from "react-typist";
import { BigNumber } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { GameStates } from "../../game-states";
import { Connection } from "./Connection";
import { MGearWithStats } from "../MGearWithStats";
import { MGearWithStatsMPunk } from "../MGearWithStatsMPunk";
import { hash } from "../Mine/mine";
import { Computer } from "../Computer";
import { useEffect, useState } from "react";
import { TextContainer } from "../TextContainer";

const gameStateToDialogue: string[] = [];

gameStateToDialogue[
  GameStates.UNCONNECTED
] = `Greetings, traveler. Welcome to mineable space. Your arrival is quite fortunate.

I am the Ethersmith, guardian of mspace. Everything here is 100% on-chain and made of mineable energy. However, this plane is under attack by beings called mfiends, and we need heroes like you to defeat and capture them.

Will you help?`;

gameStateToDialogue[
  GameStates.INTRO
] = `The war against mfiends is raging by the dark portal. I can help prepare you for battle. What do you need from me?`;

gameStateToDialogue[
  GameStates.REQUEST_GEAR
] = `Sure! I can only commit to making lower level items with your CPU power, notewothy tops, though I have a small chance to make higher level gear! What rarity are you looking for?`;

gameStateToDialogue[
  GameStates.REQUEST_COLLOSEUM
] = `The colloseum is still being built. You could fight directly through the smart contract if you'd like, but the builders still need a little time to finish the arena.`;

gameStateToDialogue[
  GameStates.REQUEST_TOWN
] = `Discord is right around the corner. Twitter is over there.`;

gameStateToDialogue[
  GameStates.REQUEST_MORE_INFO
] = `Each mgear is a 100% on-chain NFT - all art, stats, names, and utility are fully on the Ethereum blockchain. They can be one of 32 items, 6 different rarities, 3 formations, and an augmented form as well. Each item has randomized stats for each of damage, block, vigor, and celerity. The colors are random for each item as well, so the possibilities are endless!

I can use your CPU to mine for mgear, or I can transmute the souls of mpunks into gear. Transmutations have higher chances of being rare, with zombies, apes, and aliens guaranteeing rarer rolls! You get to keep your punk, too. CPU mined gear will require a .02 eth fee, and transmutations cost .01 eth.

When have some nice pieces, you can go to the changing room to put them on.`;

gameStateToDialogue[GameStates.MINE] = "Sure, I can make you some mgear. Shouldn't take too long!";

gameStateToDialogue[GameStates.MINE_FLIMSY] =
  "Sure, I can make you some normal mgear. Shouldn't take too long!";
gameStateToDialogue[GameStates.MINE_SOLID] =
  "Sure, I can make you some solid mgear. This will take me twice as long as Normal mgear, but I also have twice the chance to make rarer items!";
gameStateToDialogue[GameStates.MINE_NOTEWORTHY] =
  "Sure, I can make you some noteworthy mgear. This will take me four times as long as Normal mgear, but I also have four times the chance to make rarer items!";
gameStateToDialogue[
  GameStates.CHOOSE_OTHER
] = `I just told you, I don't have enough power from your CPU to create that rarity. I can commit to normal, solid, or noteworthy.`;

gameStateToDialogue[GameStates.FOUND] = "I just finished this piece. Do you want it?";

gameStateToDialogue[GameStates.DECLINE_MGEAR] =
  "Sad, I thought you would like it. Well I'm sure someone else will appreciate it. What do you want now?";

gameStateToDialogue[GameStates.STOP_MINING] =
  "After all the effort I already put in?! Ugh. Well what do you want instead?";

gameStateToDialogue[GameStates.NOT_CONNECTED] = "You need to connect your wallet for that.";

gameStateToDialogue[GameStates.AFTER_MINT] =
  "Nice piece, if I do say so myself. Try it on in the changing room if you got a minute.";

gameStateToDialogue[GameStates.CHANGING_ROOM] =
  "Welcome to the changing room, you can try on mgear that you own and then save your look for a PFP.";

gameStateToDialogue[GameStates.AFTER_EQUIP] =
  "Nice gear set. If you're ready to take on some mfiends, go wait by the dark portal.";

gameStateToDialogue[GameStates.ASK_TO_TRANSMUTE] =
  "Which punk do you want to transmute? This might hurt a little bit, but your punk should be okay.";

gameStateToDialogue[GameStates.TRANSMUTING] = "I need to focus...........";

gameStateToDialogue[GameStates.TRANSMUTED_PUNK] =
  "I've successfully transformed the soul of your punk into this mgear. Do you want it?";

gameStateToDialogue[GameStates.WHERE_PUNKS] =
  "You can use my computer to access the mpunks program. Sorry I haven't updated my OS in a bit.";

gameStateToDialogue[GameStates.WHERE_WORDS] =
  "I've pulled up a terminal with the program required to mine mwords. Hop on!";

gameStateToDialogue[GameStates.AFTER_BACKUP] =
  "I've successfully captured that monster for you. Good teamwork.";

gameStateToDialogue[GameStates.INSCRIBE_NAME_MINED] = `
What would you like to inscribe your mgear with? To get different inscriptions, you'll need mwords. You can always inscribe or reinscribe mgear later. Once you choose the name, I'll create your gear.
`;

gameStateToDialogue[GameStates.INSCRIBE_NAME_PUNK] =
  gameStateToDialogue[GameStates.INSCRIBE_NAME_MINED];

gameStateToDialogue[GameStates.RENAME_PICK_MGEAR] =
  "I can certainly rename your mgear. Which mgear would you like to rename?";

gameStateToDialogue[GameStates.AFTER_CAPTURE] = "Good job capturing that mfiend. I'm impressed.";

gameStateToDialogue[GameStates.RENAME_PICK_MWORD] =
  "Pick what you would like to name your mgear and I'll get right on it.";

gameStateToDialogue[GameStates.NO_WALLET] =
  "Hm, looks like you don't have an Ethereum wallet, or that you're not logged in to it. May I suggest Metamask?";
//Questions

gameStateToDialogue[GameStates.QUESTIONS] = "Ask away.";

gameStateToDialogue[GameStates.RETURN_TO_INTRO] = "Anything else I can do for you?";

gameStateToDialogue[GameStates.DECLINE_RENAME] = "Suit yourself.";

gameStateToDialogue[GameStates.MPUNKS_LOCKED] =
  "I still have enough power from your CPU to make mgear. Once I've made 2048 pieces, I'll need your mpunk souls to get enough energy.";

gameStateToDialogue[GameStates.AFTER_RENAME] = "I think that inscription is better, too.";

gameStateToDialogue[
  GameStates.WHAT_ARE_MGEAR
] = `Mgear are 100% on-chain equippable items with infinite possibilites. I can create them for you right here by using the power from your CPU through the process of mining.`;

gameStateToDialogue[
  GameStates.WHAT_MGEAR_KINDS
] = `Each piece can be one of 32 items, 6 different rarities, 3 formations, and an augmented form as well. Each item has randomized stats depending on rarity, ranging from 0-9 for each of damage, block, vigor, and celerity. The colors are random for each item as well, so the possibilities are endless!`;

gameStateToDialogue[
  GameStates.WHO_ARE_YOU
] = `I am the Ethersmith, one of the Great Creators that live within mspace. 
I am tasked with finding ways to source energy from your world, and manifesting 
that energy into weapons and armor in this one. I created the mpunks and mwords programs 
in order to accumulate resources for a war that is beginning to rage - more I cannot tell you at this time.`;

gameStateToDialogue[
  GameStates.WHAT_IS_THIS_PLACE
] = `We are in mineable_space, or mspace. Everything you see here is created out of the computational power from your universe through the process of mining. Without that power, this world will stagnate and crumble. My role is to keep this place alive by sourcing that computational energy from your world.`;

gameStateToDialogue[
  GameStates.SO_YOURE_THE_ONE
] = `Yes. In order to create the strongest mgear, I needed substantial power from your world - so I created the mpunks application in order to manifest and farm their souls. You may find it cruel, but you see, they create more powerful mgear with far less of my resources… It is an evil I can live with. Mwords are ancient runes of mineable_space that do not affect the power of mgear, but there is something to be said about the sentimental attachment to your equipment.`;

gameStateToDialogue[
  GameStates.ASK_WHAT_IS_THIS_PLACE
] = `This is mineable_space, or mspace. Everything here is 100% on-chain and made out of mineable_energy, a resource generated from the process of mining. Here you can obtain mineable_gear, mineable_fiends, and mineable_words via unique variations of CPU mining right here in your browser. Each token is rendered entirely on-chain - all art, stats, and metadata will exist as long as the Ethereum blockchain does.`;

gameStateToDialogue[
  GameStates.ASK_WHAT_ARE_MFIENDS
] = `mfiends are otherworldly parasites who feed on mineable_energy - computational power from your world, transferred into this one through mining. They can take on virtually unlimited forms: 4 base characteristics and 2 rare characteristics (each with 16 variations), 8 different affinities with different rarities, 4 different stats, and unique color schemes. To capture mfiends, you'll need to acquire and equip mgear, and then face them at the dark portal. Through CPU mining, you will emit mineable_radiation, and within a few minutes, you should attract a fiend. Only if you defeat them through a contest of proof-of-power, a unique variation of proof-of-work, will you be able to mint them to your wallet.`;

gameStateToDialogue[
  GameStates.ASK_WHAT_ARE_STATS
] = `Ruin is your base damage. Guard is your ability to deflect damage. Vigor is your health. Celerity is your chance to dodge and critically strike. Increasing your stats increases your power level from feeble, sturdy, formidable, powerful, triumphant, to godlike. The mfiends you fight will always be slightly more powerful than you, but the gap gets smaller as you increase in power. This means that increasing your power level increases your chances to capture mfiends, and that those creatures are more powerful. Equipping rarer mgear will increase your power level even more. `;

gameStateToDialogue[
  GameStates.ASK_WHAT_IS_MGEAR
] = `Mgear are combat items that can be equipped in order to increase your power, improving your chances to defeat and capture mfiends. Each piece of mgear is aligned with aligned with one of the stats: ruin, guard, vigor, and celerity. For example, weapons would be be aligned with ruin, the stat that determines your base damage. There are 32 different items, 8 items for each of the 4 stats. They can be of 6 different rarities: normal, solid, noteworthy, extraordinary, fabled, and unreal. As items increase in rarity, they also increase in power and become more visually elaborate. Each piece of mgear also has a 1/8 chance to take on an augmented form, further developing its visual appearance. To obtain mgear, ask me to craft some for you - I will use your CPU to source mineable_energy right here in the browser to craft you a random piece. The more powerful your CPU, the faster I can craft gear.`;

gameStateToDialogue[
  GameStates.ASK_WHAT_ARE_MWORDS
] = `Mwords are the ancient runes of this world, and are used to provide vanity names for your mgear. For example, if you own the word "light", you could name your katana “light katana” or “katana of light”. Mgear can be named retroactively, so don’t worry if you don’t have mwords yet. They are created through a unique process of lexical mining, and support a bounty system for miners to get rewarded for discovering words. To obtain mwords, click on my computer to open them mwords terminal.`;

gameStateToDialogue[GameStates.RETURN_TO_UNCONNECTED] = "So? Have you decided?";
gameStateToDialogue[GameStates.QUESTIONS_UNCONNECTED] = "Naturally. What would you like to know?";

const DialogueContainer = styled.div`
  max-width: 480px;
  min-width: 480px;
  text-align: left;
  background-color: rgba(50, 50, 50);
  padding: 12px;
  border: 4px solid rgba(80, 80, 80);
`;

const SubContainer = styled.div`
  margin-top: 16px;
  max-width: 500px;
  text-align: left;
  background-color: rgba(50, 50, 50);
  padding: 12px;
  border: 4px solid rgba(80, 80, 80);
`;

const Container = styled.div`
  padding-top: 160px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  min-height: 400px;
`;

type DialogueProps = {
  gameState: number;
  hashRate: number;
  found: BigNumber | null;
  rarityIndex: number;
  stagedMPunk: number | null;
  setGameState: Dispatch<SetStateAction<number>>;
};

const getDialogueFromGameState = (gameState: number, connected: boolean) => {
  if (gameState === GameStates.CONNECT) {
    if (connected) return "Looks like you're already connected. Here's your account.";
    return "Splendid. You will need to gear up with mgear to be able to take on the attacking mfiends. I can make you mgear here in my workshop - it will require some time and mineable energy from your CPU. Once you’ve collected some gear, let me know when you’re ready to fight.";
  }
  if (gameState === GameStates.REQUEST_GEAR) {
    if (connected) return gameStateToDialogue[gameState];
    return "You'll need to connect you wallet first.";
  }
  return gameStateToDialogue[gameState] || "I wasn't programmed to know what to say here.";
};

const getEstimatedTime = (rarityIndex: number, hashRate: number) => {
  return Math.round(2 ** (26 + rarityIndex) / (hashRate * 60));
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const Dialogue = ({
  gameState,
  hashRate,
  found,
  rarityIndex,
  stagedMPunk,
  setGameState,
}: DialogueProps) => {
  const [audio] = useState(
    new Audio("https://audius-discovery-3.altego.net/v1/tracks/xy6P2/stream?app_name=MGEAR")
  );
  const { library, account } = useWeb3React<Web3Provider>();
  const isMining =
    gameState === GameStates.MINE_FLIMSY ||
    gameState === GameStates.MINE_SOLID ||
    gameState === GameStates.MINE_NOTEWORTHY ||
    gameState === GameStates.MINE;

  const connected = !!library && !!account;

  useEffect(() => {
    const sound = async () => {
      const length = getDialogueFromGameState(gameState, connected).length;
      audio.play();
      await sleep(20 * length * 1.2);
      audio.pause();
    };
    //enabled
    if (false) {
      sound();
    }
  }, [gameState, audio, connected]);

  return (
    <Container>
      <DialogueContainer>
        <Typist avgTypingDelay={14} key={gameState}>
          {getDialogueFromGameState(gameState, connected)}
        </Typist>
      </DialogueContainer>
      {gameState === GameStates.CONNECT && <Connection setGameState={setGameState} />}
      {isMining && !!hashRate && (
        <SubContainer>
          <div style={{ textAlign: "left" }}>
            My hash rate is around... {hashRate} hashes per second. I estimate it will take me about{" "}
            {getEstimatedTime(0, hashRate)} minutes.
          </div>
        </SubContainer>
      )}
      {gameState === GameStates.FOUND && found && (
        <MGearWithStats
          mgear={hash({ address: BigNumber.from(account), nonce: found })}
          rarityIndex={rarityIndex}
        />
      )}
      {gameState === GameStates.TRANSMUTED_PUNK && stagedMPunk && (
        <MGearWithStatsMPunk mpunk={stagedMPunk} />
      )}
      {gameState === GameStates.RENAME_PICK_MGEAR && (
        <TextContainer style={{ display: "inline-block", fontSize: "12px" }}>
          To get words to rename your gear with, go to{" "}
          <a href="https://www.mwords.org/" style={{ color: "yellow" }}>
            mwords.org
          </a>
        </TextContainer>
      )}
      {gameState === GameStates.WHERE_PUNKS && <Computer href="https://www.mpunks.org/faq" />}
      {gameState === GameStates.WHERE_WORDS && <Computer href="https://www.mwords.org/" />}
    </Container>
  );
};
