//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const cape = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 3, 0, 0, 0, 3, 0, 0, 0, 0, 
    0, 0, 0, 0, 3, 3, 3, 0, 0, 0, 0, 0, 
    0, 0, 0, 5, 2, 2, 2, 5, 0, 0, 0, 0, 
    0, 0, 5, 2, 2, 4, 2, 2, 5, 0, 0, 0, 
    0, 0, 5, 2, 4, 1, 4, 2, 5, 0, 0, 0, 
    0, 0, 5, 2, 2, 4, 2, 2, 5, 0, 0, 0, 
    0, 0, 5, 2, 2, 2, 2, 2, 5, 0, 0, 0, 
    0, 0, 5, 2, 2, 2, 2, 2, 5, 0, 0, 0, 
    0, 0, 5, 2, 2, 2, 2, 2, 5, 0, 0, 0, 
    0, 0, 5, 3, 3, 3, 3, 3, 5, 0, 0, 0, 
    0, 0, 5, 0, 0, 0, 0, 0, 5, 0, 0, 0,
]