import SVG from "react-inlinesvg";
import { base } from "../../svgs/changing-room/base.svg";
import { renderer } from "../../svgs/renderer";
import { BigNumber } from "ethers";
import { MgearGroup } from "../mgear-to-svg";

export type Svg = {
  z: number;
  colors: string[];
  svg: number[];
  rarity: number;
  augmented: boolean;
  mgear: BigNumber;
  id?: string;
  group?: MgearGroup;
};

type ComposedProps = {
  svgs: Svg[];
};

// get offset gives us space to add colors
// we can have up to 10 colors
const getOffset = (svg: Svg) => svg.z * 10;

const compose = (svgs: Svg[]) => {
  const composedSvg = [...base];
  const sortedByZ = svgs.sort((a, b) => a.z - b.z);
  console.log({ sortedByZ });

  sortedByZ.forEach((svg) => {
    svg.svg.forEach((point, i) => {
      composedSvg[i] = getOffset(svg) + point;
    });
  });

  return composedSvg;
};

const getAllSvgColors = (svgs: Svg[]) => {
  const composed: string[] = [];
  const sortedByZ = svgs.sort((a, b) => a.z - b.z);

  sortedByZ.forEach((svg) => {
    const { colors } = svg;
    colors.forEach((color, i) => {
      composed[getOffset(svg) + i] = color;
    });
  });

  return composed;
};

export const download = (s: Svg[]) => {
  var dl = document.createElement("a");
  document.body.appendChild(dl); // This line makes it work in Firefox.

  const rendered = renderer({
    colors: getAllSvgColors(s),
    svg: compose(s),
    dimensions: { x: 37, y: 26 },
  });

  const split = rendered.split("data:image/svg+xml;utf8,");
  const dataUrl = `data:image/svg+xml;utf8,${encodeURIComponent(split[1])}`;

  dl.setAttribute("href", dataUrl);

  dl.setAttribute("download", "mgear_avatar.svg");
  dl.click();
};

export const Composed = ({ svgs }: ComposedProps) => {
  return (
    <>
      <SVG
        src={renderer({
          colors: getAllSvgColors(svgs),
          svg: compose(svgs),
          dimensions: { x: 37, y: 26 },
        })}
        height={444}
        width={624}
        style={{ transform: "translate(0px, 10px)" }}
      />
    </>
  );
};
