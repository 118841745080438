import { useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { getMWordsForAccount, getMGearFromMpunk } from "../../web3/methods";
import { MWord, MWordProps } from "./MWord";
import {
  getMGearTypeName,
  encodeMGear,
  getAdjustedRarity,
  rarityIndexToRarityName,
} from "../../utils/mgear";
import { hash } from "../Mine/mine";
import { BigNumber } from "ethers";
import { TextContainer, Center } from "../TextContainer";

const bottomStyle = {
  display: "grid",
  gridTemplateRows: "repeat(auto-fit, minmax(80px, 200px))",
  gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
  padding: "24px",
  overflow: "visible",
  gridGap: "32px",
  width: "100%",
};

export const MWordsControls = ({
  found,
  stagedMPunk,
  setGameState,
  rarity,
}: Omit<MWordProps, "word" | "index" | "format" | "gearTypeName" | "used">) => {
  const [mwords, setMwords] = useState<{ index: number; word: string; used: boolean }[] | null>(
    null
  );
  const [mgear, setMgear] = useState<BigNumber | null>(null);
  const { library, account } = useWeb3React<Web3Provider>();

  if (found && stagedMPunk) {
    throw new Error("Staged nonce and staged mpunk");
  }

  useEffect(() => {
    const fetchMWords = async () => {
      try {
        if (library && account) {
          const fetchedMWords = await getMWordsForAccount(library, account);
          setMwords(fetchedMWords);
          if (found) {
            const mgearCalc = hash({ nonce: found, address: BigNumber.from(account) });
            const adjustedRarity = getAdjustedRarity(mgearCalc, rarity);
            const encoded = encodeMGear(mgearCalc, adjustedRarity);
            setMgear(encoded);
          }
          if (stagedMPunk) {
            const mgearCalc = await getMGearFromMpunk(library, stagedMPunk);
            setMgear(mgearCalc);
          }
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    fetchMWords();
  }, [account, library, found, stagedMPunk, rarity]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextContainer small>
          Pick the name of your mgear to initiate mint. To collect more names, go to:{" "}
          <a href="https://wwww.mwords.org" style={{ color: "yellow", paddingLeft: "8px" }}>
            mwords.org
          </a>
        </TextContainer>
      </div>
      {mgear && account && (
        <MWord
          {...{
            rarity,
            used: false,
            word: rarityIndexToRarityName[getAdjustedRarity(mgear)],
            index: 0,
            found,
            stagedMPunk,
            gearTypeName: getMGearTypeName(mgear),
            setGameState,
            format: 0,
          }}
        />
      )}
      {mgear && mwords ? (
        <div style={bottomStyle}>
          {mwords.map(({ word, index, used }) => (
            <>
              <MWord
                {...{
                  rarity,
                  word,
                  used,
                  index,
                  found,
                  stagedMPunk,
                  gearTypeName: getMGearTypeName(mgear),
                  setGameState,
                  format: 0,
                }}
              />
              <MWord
                {...{
                  rarity,
                  used,
                  word,
                  index,
                  found,
                  stagedMPunk,
                  gearTypeName: getMGearTypeName(mgear),
                  setGameState,
                  format: 1,
                }}
              />
            </>
          ))}
        </div>
      ) : (
        <Center>
          <TextContainer>Loading your mwords...</TextContainer>
        </Center>
      )}
    </>
  );
};
