import "./App.css";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import { TalkOptions } from "./components/TalkOptions";
import { Dialogue } from "./components/Dialogue";
import { Blacksmith } from "./components/Blacksmith";
import { useMine, MiningStatus } from "./components/Mine";
import { hash } from "./components/Mine/mine";
import { BigNumber } from "ethers";
import { GameStates } from "./game-states";
import { RarityIndex } from "./utils/mgear";
import { guyNoGear } from "./components/svgs/changing-room/guy";
import { MPunksControls } from "./components/MPunks";
import { Stars } from "./components/Stars";
import { MWordsControls } from "./components/MWords";
import { MWordsToRename } from "./components/MWordsToRename";
import { MGearToRename } from "./components/MGearToRename";
import { Composed, Svg } from "./components/ChangingRoom/Composed";
import {
  skinColors,
  hairColors,
  eyeColors,
  clothingColors,
  armColors,
} from "./components/ChangingRoom/CharacterControls";
import { ChangingRoom } from "./components/ChangingRoom";
import { abi } from "./contracts/abis/mgear";
import { useFullStoryWeb3 } from "./web3/use-fullstory-web3";
import { Battle } from "./components/Battle";
// import { QA } from "./components/QA";
// import { encodeAll } from "./components/svgs/new-assets/compressor";
import { Layout, Area } from "./layout";
import { tokenURI } from "./web3/methods-monsters";

const Hover = styled.div`
  @keyframes bs-float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  animation: bs-float 4s ease-in-out infinite;
  width: 70%;
`;

const FullScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  overflow: hidden;

  font-family: "VT323", monospace;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: fade-in 1s ease-in-out;
`;

const fadeInStyle = { animation: "fade-in 0.8s ease-in-out" };

export const gameStatesToDifficultyTargets: BigNumber[] = [];

const raritiesToDifficulties = [
  BigNumber.from("0x0000003fffffffffffffffffffffffffffffffffffffffffffffffffffffffff"),
  BigNumber.from("0x0000001fffffffffffffffffffffffffffffffffffffffffffffffffffffffff"),
  BigNumber.from("0x0000000fffffffffffffffffffffffffffffffffffffffffffffffffffffffff"),
];

export const easyOverride = () => {
  // EASY OVERRIDE
  raritiesToDifficulties[0] = BigNumber.from(
    "0x003fffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
  );
  raritiesToDifficulties[1] = BigNumber.from(
    "0x001effffefffffffffffffffffffffffffffffffffffffffffffffffffffffff"
  );
  raritiesToDifficulties[2] = BigNumber.from(
    "0x000fffffdfffffffffffffffffffffffffffffffffffffffffffffffffffffff"
  );
};

// easyOverride();

gameStatesToDifficultyTargets[GameStates.MINE_FLIMSY] = raritiesToDifficulties[0];
gameStatesToDifficultyTargets[GameStates.MINE_SOLID] = raritiesToDifficulties[1];
gameStatesToDifficultyTargets[GameStates.MINE_NOTEWORTHY] = raritiesToDifficulties[2];

const gameStateToRarityIndex: RarityIndex[] = [];

gameStateToRarityIndex[GameStates.MINE_FLIMSY] = RarityIndex.FLIMSY;
gameStateToRarityIndex[GameStates.MINE_SOLID] = RarityIndex.SOLID;
gameStateToRarityIndex[GameStates.MINE_NOTEWORTHY] = RarityIndex.NOTEWORTHY;

const randomNumber = (max: number) => {
  const num = Math.floor(Math.random() * max);
  return num;
};

const guySvg = {
  svg: guyNoGear,
  z: 1,
  colors: [
    "none",
    hairColors[randomNumber(hairColors.length)],
    eyeColors[randomNumber(eyeColors.length)],
    skinColors[randomNumber(skinColors.length)],
    armColors[randomNumber(armColors.length)],
    clothingColors[randomNumber(clothingColors.length)],
  ],
  augmented: false,
  rarity: 0,
  mgear: BigNumber.from(0),
};

function App() {
  useFullStoryWeb3({ orgId: "14VR7Y", abi });
  const { account, library } = useWeb3React<Web3Provider>();

  const [gameState, setGameState] = useState<number>(0);

  useEffect(() => {
    if (account && library) {
      setGameState(GameStates.INTRO);
    }
  }, []);

  const [miningStatus, setMiningStatus] = useState<MiningStatus>(MiningStatus.STOPPED);
  const [rarityIndex, setRarityIndex] = useState<number>(RarityIndex.FLIMSY);
  const [stagedMPunk, setStagedMPunk] = useState<number | null>(null);
  const [stagedMGearToRename, setStagedMGearIdForRename] = useState<{
    mgear: BigNumber;
    id: BigNumber;
    name: string;
  } | null>(null);

  //Changing room state

  const [svgs, setSvgs] = useState<Svg[]>([guySvg]);

  const { found, hashRate } = useMine({
    difficultyTarget: raritiesToDifficulties[0],
    miningStatus,
    setMiningStatus,
  });

  useEffect(() => {
    if (gameState === GameStates.MINE) {
      setMiningStatus(MiningStatus.WAITING_TO_START);
    } else {
      setMiningStatus(MiningStatus.WAITING_TO_STOP);
    }
  }, [gameState]);

  // uncomment to log a token

  // useEffect(() => {
  //   if (library && account) tokenURI(library!, account!, 1);
  // }, [library, account]);

  useEffect(() => {
    if (found) {
      const target = hash({ address: BigNumber.from(account!), nonce: found });
      setRarityIndex(0);
      if (target.lt(raritiesToDifficulties[1])) {
        setRarityIndex(1);
      }
      if (target.lt(raritiesToDifficulties[2])) {
        setRarityIndex(2);
      }

      setGameState(GameStates.FOUND);
    }
  }, [found, account]);

  // console.log(encodeAll());

  const isBattleState =
    gameState === GameStates.BATTLE ||
    gameState === GameStates.BATTLE_EQUIP_ITEMS ||
    gameState === GameStates.APPROACH_PORTAL ||
    gameState === GameStates.CALL_ETHERSMITH_FOR_BACKUP ||
    gameState === GameStates.BATTLE_PREPARE;

  return (
    <FullScreen>
      <Stars count={30} />
      <FullScreen style={{ overflow: "auto" }}>
        {!isBattleState ? (
          <Layout
            style={
              gameState === GameStates.CHANGING_ROOM
                ? { gridTemplateRows: "1fr 1fr", ...fadeInStyle }
                : fadeInStyle
            }
          >
            <Area name="dialogue">
              <Dialogue
                gameState={gameState}
                hashRate={hashRate}
                found={found}
                rarityIndex={rarityIndex}
                stagedMPunk={stagedMPunk}
                setGameState={setGameState}
              />
            </Area>
            <Area name="ethersmith">
              <>
                {gameState === GameStates.CHANGING_ROOM ? (
                  <Composed svgs={svgs} />
                ) : (
                  <Hover>
                    <Blacksmith gameState={gameState} />
                  </Hover>
                )}
              </>
              )
            </Area>
            <Area name="options">
              <TalkOptions
                toGameState={(toGameState: number) => setGameState(toGameState)}
                gameState={gameState}
                found={found}
                rarityIndex={rarityIndex}
                stagedMPunk={stagedMPunk}
                svgs={svgs}
              />
            </Area>
            <Area name="bottom">
              {gameState === GameStates.ASK_TO_TRANSMUTE && (
                <MPunksControls {...{ setGameState, setStagedMPunk }} />
              )}
              {gameState === GameStates.INSCRIBE_NAME_MINED && (
                <MWordsControls
                  {...{ setGameState, found, stagedMPunk: null, rarity: rarityIndex }}
                />
              )}
              {gameState === GameStates.INSCRIBE_NAME_PUNK && (
                <MWordsControls
                  {...{ setGameState, found: null, stagedMPunk, rarity: rarityIndex }}
                />
              )}
              {gameState === GameStates.RENAME_PICK_MGEAR && (
                <MGearToRename {...{ setGameState, setStagedMGearIdForRename }} />
              )}
              {gameState === GameStates.RENAME_PICK_MWORD && stagedMGearToRename && (
                <MWordsToRename {...{ setGameState, stagedMGearToRename }} />
              )}
              {gameState === GameStates.CHANGING_ROOM && (
                <ChangingRoom
                  {...{
                    svgs,
                    setSvgs,
                  }}
                />
              )}
            </Area>
          </Layout>
        ) : (
          <Battle gameState={gameState} setGameState={setGameState} />
        )}
      </FullScreen>
    </FullScreen>
  );
}

export default App;
