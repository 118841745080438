import {
  Intro,
  Talking,
  Leaning,
  LeaningAndTalking,
  Crafting1,
  Crafting2,
  Transmute1,
  Transmute2,
  Transmute3,
} from "../svgs/ethersmith";

import ReactInterval from "react-interval";
import styled from "styled-components";
import { useState } from "react";
import { GameStates } from "../../game-states";

type BlacksmithProps = { gameState: number };

const BlackSmith = styled.div`
  transition: all 1s ease;
  display: flex;
  flex-direction: column;
  max-height: 500px;
`;

const Mining = () => {
  const [up, setUp] = useState<boolean>(true);

  return (
    <>
      <ReactInterval
        timeout={300}
        enabled
        callback={() => {
          setUp((value) => !value);
        }}
      />
      {up ? <Crafting1 /> : <Crafting2 />}
    </>
  );
};

const transmuteFrames = [<Transmute1 />, <Transmute2 />, <Transmute3 />, <Transmute2 />];

const Transmute = () => {
  console.log("rendered transmute");
  const [frame, setFrame] = useState<number>(0);

  return (
    <>
      <ReactInterval
        timeout={300}
        enabled
        callback={() => {
          if (frame === 3) setFrame(0);
          else setFrame((f) => f + 1);
        }}
      />
      {transmuteFrames[frame]}
    </>
  );
};

const gameStateToSvg: React.ReactElement[] = [];

gameStateToSvg[GameStates.INTRO] = <Intro />;
gameStateToSvg[GameStates.CONNECT] = <Leaning />;
gameStateToSvg[GameStates.REQUEST_GEAR] = <LeaningAndTalking />;
gameStateToSvg[GameStates.REQUEST_COLLOSEUM] = <Talking />;
gameStateToSvg[GameStates.REQUEST_TOWN] = <Talking />;
gameStateToSvg[GameStates.REQUEST_MORE_INFO] = <Intro />;
gameStateToSvg[GameStates.MINE] = <Mining />;
gameStateToSvg[GameStates.MINE_FLIMSY] = <Mining />;
gameStateToSvg[GameStates.MINE_SOLID] = <Mining />;
gameStateToSvg[GameStates.MINE_NOTEWORTHY] = <Mining />;
gameStateToSvg[GameStates.TRANSMUTING] = <Transmute />;
gameStateToSvg[GameStates.CHOOSE_OTHER] = <Intro />;
gameStateToSvg[GameStates.FOUND] = <Talking />;
gameStateToSvg[GameStates.TRANSMUTED_PUNK] = <Talking />;
gameStateToSvg[GameStates.QUESTIONS] = <Leaning />;
gameStateToSvg[GameStates.WHAT_IS_THIS_PLACE] = <Talking />;
gameStateToSvg[GameStates.WHERE_PUNKS] = <LeaningAndTalking />;
gameStateToSvg[GameStates.WHERE_WORDS] = <Talking />;
gameStateToSvg[GameStates.WHAT_MGEAR_KINDS] = <LeaningAndTalking />;
gameStateToSvg[GameStates.ASK_TO_TRANSMUTE] = <Talking />;
gameStateToSvg[GameStates.NOT_CONNECTED] = <Talking />;
gameStateToSvg[GameStates.CALL_ETHERSMITH_FOR_BACKUP] = <Transmute />;

export const Blacksmith = ({ gameState }: BlacksmithProps) => {
  return <BlackSmith key={gameState}>{gameStateToSvg[gameState] || <Intro />}</BlackSmith>;
};
