import styled from "styled-components";
import { NormalizedMgear } from "../../web3/methods";
import {
  getGearQuality,
  getRarityIndex,
  getStatTotal,
  getGearType,
  statIndexToStatName,
  getStats,
} from "../../utils/mgear";
import { MGear } from "../MGear";

const MGearContainer = styled.div<{ onClick: () => void; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 120px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  ${(props) => (props.disabled ? "opacity: 0.5;" : "")}
  &:hover {
    opacity: 0.7;
  }
  min-height: 200px;
`;

type MGearDisplayProps = {
  mgear: NormalizedMgear;
  onClick: (mgear: NormalizedMgear) => void;
  animate?: boolean;
  showStats?: boolean;
  disabled?: boolean;
};

export const MGearDisplay = ({
  mgear,
  onClick,
  animate = true,
  showStats,
  disabled,
}: MGearDisplayProps) => {
  const stats = getStats(mgear.mgear, getRarityIndex(mgear.mgear));
  const statKeys = Object.keys(stats);
  return (
    <MGearContainer onClick={() => (!disabled ? onClick(mgear) : null)} disabled={disabled}>
      <MGear mgear={mgear.mgear} renderOnChain animate={animate} />
      <div style={{ flexGrow: 1 }}>{mgear.name}</div>
      <div style={{ fontSize: "12px", display: "flex", flexDirection: "column" }}>
        <div>item type: {statIndexToStatName[getGearType(mgear.mgear)]}</div>
        <div>quality: {getGearQuality(getRarityIndex(mgear.mgear), getStatTotal(mgear.mgear))}</div>
        {showStats && (
          <div
            style={{
              fontSize: "10px",
              display: "flex",
              flexDirection: "column",
              color: "yellow",
            }}
          >
            {statKeys.map((stat) => (
              <div>
                {stat}: +{stats[stat]}
              </div>
            ))}
          </div>
        )}
      </div>
    </MGearContainer>
  );
};
