//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const amulet = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 
    0, 0, 0, 0, 0, 0, 2, 2, 0, 0, 2, 0, 
    0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 2, 0, 
    0, 0, 0, 0, 0, 0, 2, 0, 2, 2, 0, 0, 
    0, 0, 0, 0, 0, 2, 0, 0, 2, 0, 0, 0, 
    0, 0, 0, 0, 0, 2, 0, 2, 2, 0, 0, 0, 
    0, 0, 4, 3, 3, 2, 2, 2, 0, 0, 0, 0, 
    0, 0, 3, 1, 1, 3, 0, 0, 0, 0, 0, 0, 
    0, 0, 3, 1, 1, 3, 0, 0, 0, 0, 0, 0, 
    0, 5, 4, 3, 3, 4, 0, 0, 0, 0, 0, 0, 
    0, 5, 5, 0, 0, 0, 0, 0, 0, 0, 0, 0,
]