import SVG from "react-inlinesvg";
import { renderer } from "../renderer";
import { portal1 } from "./portal1.svg";
import { portal2 } from "./portal2.svg";
import { portal3 } from "./portal3.svg";
import { portal4 } from "./portal4.svg";

const colors = ["#c74fff", "#2e222c", "#ff24de", "#6b21b5", "#5b2261"];
const dimensions = { x: 21, y: 21 };

export const Portal1 = ({ style }: any) => (
  <SVG src={renderer({ svg: portal1, colors, dimensions })} style={style} />
);
export const Portal2 = ({ style }: any) => (
  <SVG src={renderer({ svg: portal2, colors, dimensions })} style={style} />
);
export const Portal3 = ({ style }: any) => (
  <SVG src={renderer({ svg: portal3, colors, dimensions })} style={style} />
);
export const Portal4 = ({ style }: any) => (
  <SVG src={renderer({ svg: portal4, colors, dimensions })} style={style} />
);
