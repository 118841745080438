import {
  skinColors,
  hairColors,
  eyeColors,
  clothingColors,
  armColors,
} from "../../components/ChangingRoom/CharacterControls";
import { guyNoGear } from "./changing-room/guy";
import { BigNumber } from "ethers";

const randomNumber = (max: number) => {
  const num = Math.floor(Math.random() * max);
  return num;
};

export const guySvg = {
  svg: guyNoGear,
  z: 1,
  colors: [
    "none",
    hairColors[randomNumber(hairColors.length)],
    eyeColors[randomNumber(eyeColors.length)],
    skinColors[randomNumber(skinColors.length)],
    armColors[randomNumber(armColors.length)],
    clothingColors[randomNumber(clothingColors.length)],
  ],
  augmented: false,
  rarity: 0,
  mgear: BigNumber.from(0),
};

export const loadingGuy = {
  svg: guyNoGear,
  z: 1,
  colors: ["none", "0x0", "0x0", "0x0", "0x0", "0x0"],
  augmented: false,
  rarity: 0,
  mgear: BigNumber.from(0),
};

export const getColorsForAddress = (address: BigNumber) => [
  "none",
  address.and("0xFFFFFF")._hex.replace("0x", "#"),
  address.shr(24).and("0xFFFFFF")._hex.replace("0x", "#"),
  address.shr(48).and("0xFFFFFF")._hex.replace("0x", "#"),
  address.shr(72).and("0xFFFFFF")._hex.replace("0x", "#"),
  address.shr(96).and("0xFFFFFF")._hex.replace("0x", "#"),
];

export const guySvgFromAddress = (address: BigNumber) => ({
  svg: guyNoGear,
  z: 1,
  colors: getColorsForAddress(address),
  augmented: false,
  rarity: 0,
  mgear: BigNumber.from(0),
});
