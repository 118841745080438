import styled from "styled-components";

type HealthBarProps = {
  total: number;
  damageTaken: number;
};

const Unit = styled.div<{ isDamage?: boolean }>`
  width: 8px;
  background-color: ${(props) => (props.isDamage ? "red" : "green")};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  z-index: 1;
`;

export const HealthBar = ({ total, damageTaken }: HealthBarProps) => {
  const healthLeft = total - damageTaken <= 0 ? 0 : total - damageTaken;
  return (
    <Container>
      {healthLeft}/{total}
      {new Array(healthLeft).fill(<Unit />)}
      {new Array(damageTaken).fill(<Unit isDamage />)}
    </Container>
  );
};
