import styled from "styled-components";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { BigNumber } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { getMGearForAccount } from "../../web3/methods";
import { MGear } from "../MGear";
import { GameStates } from "../../game-states";
import { TextContainer } from "../TextContainer";
import { bottomStyle } from "../../utils/mgear";

type MGearToRenameProps = {
  setStagedMGearIdForRename: Dispatch<
    SetStateAction<{
      mgear: BigNumber;
      id: BigNumber;
      name: string;
    } | null>
  >;
  setGameState: Dispatch<SetStateAction<GameStates>>;
};

const MGearContainer = styled.div<{ onClick: () => void }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const MGearToRename = ({ setStagedMGearIdForRename, setGameState }: MGearToRenameProps) => {
  const [mgear, setMgear] = useState<{ mgear: BigNumber; name: string; id: BigNumber }[] | null>(
    null
  );
  const { library, account } = useWeb3React<Web3Provider>();

  useEffect(() => {
    const fetchMgear = async () => {
      try {
        if (library && account) {
          const fetchedMGear = await getMGearForAccount(library, account);
          setMgear(fetchedMGear);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    fetchMgear();
  }, [account, library]);

  return mgear ? (
    <>
      {mgear.length > 0 && (
        <div style={bottomStyle}>
          {mgear.map((mg) => (
            <MGearContainer
              onClick={() => {
                setStagedMGearIdForRename(mg);
                setGameState(GameStates.RENAME_PICK_MWORD);
              }}
            >
              <MGear mgear={mg.mgear} renderOnChain />
              <div>{mg.name}</div>
            </MGearContainer>
          ))}
        </div>
      )}
      {mgear.length === 0 && (
        <Center>
          <TextContainer>You have no mgear.</TextContainer>
        </Center>
      )}
    </>
  ) : (
    <Center>
      <TextContainer>Loading your mgear...</TextContainer>
    </Center>
  );
};
