type Networks = {
  LOCAL: string | undefined;
  ROPSTEN: string | undefined;
  RINKEBY: string | undefined;
  GOERLI: string | undefined;
  MAINNET: string | undefined;
};

export const chainIds: { [key in keyof Networks]: number } = {
  MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GOERLI: 5,
  LOCAL: 1340,
};

export const MINEABLE_PUNKS_RINKEBY = "0x4d147A7A10F6E2Bf957F6e2c2B107F58C119c54a";

export const chainUrls: { [key in keyof Networks]: string } = {
  MAINNET: process.env.REACT_APP_MAINNET_URL || "https://cloudflare-eth.com",
  ROPSTEN:
    process.env.REACT_APP_ROPSTEN_URL ||
    "https://ropsten.infura.io/v3/d37e4dbaecf44046817af6c860a500bf",
  RINKEBY:
    process.env.REACT_APP_RINKEBY_URL ||
    "https://eth-rinkeby.alchemyapi.io/v2/5CWm_FjVnQUsRbiSl14P9QIy4G6T_kzL",
  GOERLI: "https://eth-goerli.g.alchemy.com/v2/Df595shlvdkYa1Z3coloSM9CzObybj9v",
  LOCAL: "http://localhost:8545",
};

export const contractAddresses: Networks = {
  LOCAL: process.env.REACT_APP_MINEABLE_GEAR_ADDRESS_LOCAL,
  ROPSTEN: process.env.REACT_APP_MINEABLE_GEAR_ADDRESS_ROPSTEN,
  MAINNET: process.env.REACT_APP_MINEABLE_GEAR_ADDRESS_MAINNET,
  RINKEBY: process.env.REACT_APP_MINEABLE_GEAR_ADDRESS_RINKEBY,
  GOERLI: process.env.REACT_APP_MINEABLE_GEAR_ADDRESS_GOERLI,
};

export const monstersAddresses: Networks = {
  LOCAL: process.env.REACT_APP_MINEABLE_MONSTERS_ADDRESS_LOCAL,
  ROPSTEN: process.env.REACT_APP_MINEABLE_MONSTERS_ADDRESS_LOCAL,
  MAINNET: process.env.REACT_APP_MINEABLE_MONSTERS_ADDRESS_MAINNET,
  RINKEBY: process.env.REACT_APP_MINEABLE_MONSTERS_ADDRESS_LOCAL,
  GOERLI: process.env.REACT_APP_MINEABLE_MONSTERS_ADDRESS_GOERLI,
};

export const punkContractAddresses: Networks = {
  LOCAL: process.env.REACT_APP_MINEABLE_PUNKS_ADDRESS_LOCAL,
  ROPSTEN: process.env.REACT_APP_MINEABLE_PUNKS_ADDRESS_ROPSTEN,
  MAINNET: process.env.REACT_APP_MINEABLE_PUNKS_ADDRESS_MAINNET,
  RINKEBY: process.env.REACT_APP_MINEABLE_PUNKS_ADDRESS_RINKEBY,
  GOERLI: process.env.REACT_APP_MINEABLE_PUNKS_ADDRESS_GOERLI,
};

export const wordsContractAddresses: Networks = {
  LOCAL: process.env.REACT_APP_MINEABLE_WORDS_ADDRESS_LOCAL,
  ROPSTEN: process.env.REACT_APP_MINEABLE_WORDS_ADDRESS_ROPSTEN,
  MAINNET: process.env.REACT_APP_MINEABLE_WORDS_ADDRESS_MAINNET,
  RINKEBY: process.env.REACT_APP_MINEABLE_WORDS_ADDRESS_RINKEBY,
  GOERLI: process.env.REACT_APP_MINEABLE_WORDS_ADDRESS_GOERLI,
};

export const network = (process.env.REACT_APP_NETWORK as keyof Networks) || ("LOCAL" as "LOCAL");

export const MINEABLEGEAR_ADDR = contractAddresses[network] || "";
export const MINEABLEPUNKS_ADDR = punkContractAddresses[network] || "";
export const MINEABLEWORDS_ADDR = wordsContractAddresses[network] || "";
export const MINEABLEMONSTERS_ADDR = monstersAddresses[network] || "";

console.log({
  MINEABLEGEAR_ADDR,
  MINEABLEPUNKS_ADDR,
  MINEABLEWORDS_ADDR,
  MINEABLEMONSTERS_ADDR,
  network,
});
