import SVG from "react-inlinesvg";
import { renderer } from "../renderer";
import { svg as introSvg } from "./blacksmith-intro-with-base.svg";
import { svg as leaningSvg } from "./blacksmith-leaning-with-base.svg";
import { svg as talkingSvg } from "./blacksmith-talking-with-base.svg";
import { svg as leaningAndTalkingSvg } from "./blacksmith-leaning-and-talking-with-base.svg";
import { svg as crafting1 } from "./blacksmith-1-with-base.svg";
import { svg as crafting2 } from "./blacksmith-2-with-base.svg";
import { svg as transmute1 } from "./blacksmith-transmute-1.svg";
import { svg as transmute2 } from "./blacksmith-transmute-2.svg";
import { svg as transmute3 } from "./blacksmith-transmute-3.svg";

export const Intro = () => <SVG src={renderer(introSvg)} width={"100%"} />;

export const Leaning = () => <SVG src={renderer(leaningSvg)} />;

export const Talking = () => <SVG src={renderer(talkingSvg)} />;

export const LeaningAndTalking = () => <SVG src={renderer(leaningAndTalkingSvg)} />;

export const Crafting1 = () => <SVG src={renderer(crafting1)} />;

export const Crafting2 = () => <SVG src={renderer(crafting2)} />;

export const Transmute1 = () => <SVG src={renderer(transmute1)} />;

export const Transmute2 = () => <SVG src={renderer(transmute2)} />;

export const Transmute3 = () => <SVG src={renderer(transmute3)} />;
