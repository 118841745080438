import { Web3Provider } from "@ethersproject/providers";
import { Mineablepunks__factory } from "../mineablepunks";
import { MGear__factory } from "../contracts/mineablegear";
import { MineableWords__factory } from "../contracts/mineablewords";
import { BigNumber, utils } from "ethers";
import { hash } from "../components/Mine/mine";
import { MINEABLEGEAR_ADDR, MINEABLEPUNKS_ADDR, MINEABLEWORDS_ADDR } from ".";
import { encodeMGear } from "../utils/mgear";

export type NormalizedMgear = {
  id: BigNumber;
  mgear: BigNumber;
  name: string;
  attributes: { trait_type: string; value: string }[];
};

export const renderMGear = async function (
  lib: Web3Provider,
  mgear: BigNumber,
  normalize: boolean = true
): Promise<string> {
  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const mask = "0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFE0007FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF";
    const data = await contract.renderData(mgear.and(mask));
    const base64 = data.replace("data:application/json;base64,", "");
    const decoded = atob(base64);
    const parsed = JSON.parse(decoded);
    return parsed.image;
  } catch (e: any) {
    const message: string = e.message;
    console.log(message);
    throw e;
  }
};

export const renderData = async function (
  lib: Web3Provider,
  mgear: BigNumber,
  rarity?: number
): Promise<string> {
  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const data = await contract.renderData(mgear);
    const base64 = data.replace("data:application/json;base64,", "");
    const decoded = atob(base64);
    const parsed = JSON.parse(decoded);
    return parsed;
  } catch (e: any) {
    const message: string = e.message;
    console.log(message);
    throw e;
  }
};

export const render = async function (lib: Web3Provider, mgear: BigNumber): Promise<string> {
  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const svg = await contract.renderData(mgear);
    return svg;
  } catch (e: any) {
    const message: string = e.message;
    console.log(message);
    throw e;
  }
};

export const attemptMint = async function (
  lib: Web3Provider,
  nonce: BigNumber,
  rarity: BigNumber,
  mwordIndex?: number,
  nameFormat?: number
): Promise<string> {
  if (nameFormat !== undefined) {
    if (nameFormat < 0 || nameFormat > 1) {
      throw new Error("bad format");
    }
  }

  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const signer = lib.getSigner();

    const value = utils.parseEther("0.02");

    const tx = await contract
      .connect(signer)
      .mint(nonce.toHexString(), rarity, mwordIndex || 0, nameFormat || 0, {
        value,
      });

    console.log({ tx });

    return tx.hash;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const attemptTransmutation = async function (
  lib: Web3Provider,
  mpunk: number,
  mwordIndex?: number,
  nameFormat?: number
): Promise<string> {
  if (nameFormat !== undefined) {
    if (nameFormat < 0 || nameFormat > 1) {
      throw new Error("bad format");
    }
  }

  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const signer = lib.getSigner();

    const value = utils.parseEther("0.02");

    const tx = await contract
      .connect(signer)
      .transmute(BigNumber.from(mpunk), mwordIndex || 0, nameFormat || 0, {
        value,
      });

    console.log({ tx });

    return tx.hash;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const attemptRename = async function (
  lib: Web3Provider,
  mgearId: BigNumber,
  mwordIndex?: number,
  nameFormat?: number
): Promise<string> {
  if (nameFormat !== undefined) {
    if (nameFormat < 0 || nameFormat > 1) {
      throw new Error("bad format");
    }
  }

  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const signer = lib.getSigner();

    const value = utils.parseEther("0.005");

    const tx = await contract
      .connect(signer)
      .rename(
        BigNumber.from(mgearId),
        BigNumber.from(mwordIndex || 0),
        BigNumber.from(nameFormat || 0),
        {
          value,
        }
      );

    console.log({ tx });

    return tx.hash;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export type Attributes = { trait_type: string; value: string }[];

export const getMGearForAccount = async function (
  lib: Web3Provider,
  account: string
): Promise<NormalizedMgear[]> {
  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const count = await contract.balanceOf(account);
    const allMgear = [];
    for (let i = 0; i < count.toNumber(); i += 1) {
      const mgearId = await contract.tokenOfOwnerByIndex(account, i);
      const mgear = await contract.tokenIdToMGear(mgearId);
      const data = await contract.renderData(mgear);
      const base64 = data.replace("data:application/json;base64,", "");
      const decoded = atob(base64);
      const parsed = JSON.parse(decoded);
      allMgear.push({
        mgear,
        name: parsed.name,
        attributes: parsed.attributes,
        id: mgearId,
      });
    }

    return allMgear;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const getTokenIdFromMgear = async function (
  lib: Web3Provider,
  mgearId: BigNumber
): Promise<BigNumber | null> {
  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const count = await contract.totalSupply();
    for (let i = 0; i < count.toNumber(); i += 1) {
      const mId = await contract.tokenByIndex(i);
      if (mId._hex === mgearId._hex) return mId;
    }
    return null;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const getMPunksForAccount = async function (
  lib: Web3Provider,
  addr: string
): Promise<Array<{ punkId: number; used: boolean }>> {
  const mgearContract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  const contract = Mineablepunks__factory.connect(MINEABLEPUNKS_ADDR, lib);
  const transferTos = await contract.queryFilter(contract.filters.Transfer(null, addr));

  const transferFroms = await contract.queryFilter(contract.filters.Transfer(addr, null));

  const transferFromSet = new Set(transferFroms.map((r) => r.args.tokenId.toNumber()));

  const punkIds = transferTos
    .map((r) => r.args.tokenId.toNumber())
    .filter((tokenId) => !transferFromSet.has(tokenId));

  const areUsed = await Promise.all(punkIds.map((punkId) => mgearContract.transmuted(punkId)));

  const coalesced = punkIds.map((punkId, i) => ({ punkId, used: areUsed[i] }));
  return coalesced;
};

const getAllMwords = async (lib: Web3Provider) => {
  const contract = MineableWords__factory.connect(MINEABLEWORDS_ADDR, lib);
  const supply = await contract.totalSupply();
  const allMWords = [];
  for (let i = 0; i < supply.toNumber(); i += 1) {
    const mword = await contract.tokenByIndex(i);
    //mwords are 1 indexed
    allMWords.push({ mword, index: i + 1 });
  }
  return allMWords;
};

export const getAllMGear = async (
  lib: Web3Provider
): Promise<{ mgear: BigNumber; name: string; id: number; attributes: Attributes }[]> => {
  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  const supply = await contract.totalSupply();
  const allMgear = [];
  for (let i = 1; i <= supply.toNumber(); i += 1) {
    const mgear = await contract.tokenIdToMGear(i);
    const data = await contract.renderData(mgear);
    const base64 = data.replace("data:application/json;base64,", "");
    const decoded = atob(base64);
    const parsed = JSON.parse(decoded);
    allMgear.push({ mgear, name: parsed.name, attributes: parsed.attributes, id: i });
  }
  return allMgear;
};

export const getMWordsForAccount = async function (
  lib: Web3Provider,
  account: string
): Promise<Array<{ index: number; word: string; used: boolean }>> {
  const contract = MineableWords__factory.connect(MINEABLEWORDS_ADDR, lib);
  const mgearContract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const count = await contract.balanceOf(account);
    const allMwords = await getAllMwords(lib);
    const userWords = [];

    for (let i = 0; i < count.toNumber(); i += 1) {
      const mword = await contract.tokenOfOwnerByIndex(account, i);
      const decoded = await contract.decodeMword(mword);
      const index = allMwords.find((mw) => mw.mword._hex === mword._hex)?.index;
      const used = index ? await mgearContract.inscribed(index) : true;
      userWords.push({ mword, decoded, used });
    }

    return userWords.map((userWord) => ({
      index: allMwords.find((mw) => mw.mword._hex === userWord.mword._hex)?.index || -1,
      word: userWord.decoded,
      used: userWord.used,
    }));
  } catch (e: any) {
    console.log(e);
    return [];
  }
};

export const getMGearFromMGearId = async function (
  lib: Web3Provider,
  mgearId: BigNumber
): Promise<BigNumber> {
  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const mgear = await contract.tokenIdToMGear(mgearId);
    return mgear;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const getMPunkAssets = async function (
  lib: Web3Provider,
  addr: string,
  mpunk: number
): Promise<BigNumber> {
  const contract = Mineablepunks__factory.connect(MINEABLEPUNKS_ADDR, lib);
  const assets = await contract.punkIdToAssets(mpunk);
  return assets;
};

export enum MPunkBase {
  OTHER,
  ZOMBIE,
  APE,
  ALIEN,
}

export const getMPunkBase = async function (lib: Web3Provider, mpunk: number): Promise<MPunkBase> {
  const contract = Mineablepunks__factory.connect(MINEABLEPUNKS_ADDR, lib);
  const assets = await contract.punkIdToAssets(mpunk);
  const base = assets.shr(88).and("0x3f").toNumber();
  if (base === 11) return MPunkBase.ALIEN;
  if (base === 10) return MPunkBase.APE;
  if (base === 9) return MPunkBase.ZOMBIE;
  return MPunkBase.OTHER;
};

export const renderMpunk = async function (lib: Web3Provider, mpunk: number): Promise<string> {
  const contract = Mineablepunks__factory.connect(MINEABLEPUNKS_ADDR, lib);
  const svg = await contract.renderSvg(mpunk);
  return svg;
};

export const getMGearFromMpunk = async (lib: Web3Provider, mpunk: number) => {
  const mgearHash = hash({
    address: BigNumber.from(MINEABLEPUNKS_ADDR),
    nonce: BigNumber.from(mpunk),
  });

  const contract = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  const rarity = await contract.getTransmutationRarity(mgearHash, BigNumber.from(mpunk));

  return encodeMGear(mgearHash, rarity);
};
