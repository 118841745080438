import { Svg } from "../Composed";
import styled from "styled-components";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { BigNumber } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { getMGearForAccount, getAllMGear } from "../../../web3/methods";
import { mgearToSvg } from "../mgear-to-svg";
import { MGear } from "../../MGear";
import { z as Offhand } from "../../svgs/changing-room/gear/by-slot/offhand";
import { z as Mainhand } from "../../svgs/changing-room/gear/by-slot/mainhand";
import { guyNoGear, guyOnlyMain, guyOnlyOff, guyBoth } from "../../svgs/changing-room/guy";
import { getGearQuality, getRarityIndex, getStatTotal } from "../../../utils/mgear";

export type Item = {
  mgear: BigNumber;
  name: string;
  attributes: { trait_type: string; value: string }[];
};

type MGearControlsProps = {
  svgs: Svg[];
  setSvgs: Dispatch<SetStateAction<Svg[]>>;
  equipped: Item[];
  setEquipped: Dispatch<SetStateAction<Item[]>>;
};

const MGearContainer = styled.div<{ onClick: () => void }>`
  display: flex;
  flex-direction: column;
  width: 120px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  min-height: 200px;
`;

const bottomStyle = {
  display: "grid",
  gridTemplateRows: "repeat(auto-fit, minmax(80px, 200px))",
  gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
  padding: "24px",
  overflow: "visible",
  gridGap: "32px",
  width: "100%",
};

const fetchAll = false;

const isSvgTheSame = (svg1: Svg, svg2: Svg) =>
  svg1.z === svg2.z && svg1.svg.join("") === svg2.svg.join("");

export const MGearControls = ({ svgs, setSvgs, equipped, setEquipped }: MGearControlsProps) => {
  const [mgear, setMgear] = useState<
    { mgear: BigNumber; name: string; attributes: { trait_type: string; value: string }[] }[] | null
  >(null);
  const { library, account } = useWeb3React<Web3Provider>();

  const addSvg = (svg: Svg) => {
    setSvgs((prevSvgs) => {
      const alreadyAdded = prevSvgs.some((s) => isSvgTheSame(s, svg));
      const added = alreadyAdded
        ? [...prevSvgs.filter((s) => s.z !== svg.z)] // this actually removes the item if already there
        : [...prevSvgs.filter((s) => s.z !== svg.z), svg]; // add svg

      const hasMainhand = added.some((a) => a.z === Mainhand);
      const hasOffhand = added.some((a) => a.z === Offhand);

      if (hasMainhand) {
        if (hasOffhand) added[0] = { ...added[0], svg: guyBoth };
        else added[0] = { ...added[0], svg: guyOnlyMain };
      } else if (hasOffhand) {
        added[0] = { ...added[0], svg: guyOnlyOff };
      } else {
        added[0] = { ...added[0], svg: guyNoGear };
      }
      return added;
    });
  };

  const equip = (item: Item) => {
    const alreadyAdded = equipped.some((e) => e.mgear._hex === item.mgear._hex);

    const newEquipped = alreadyAdded
      ? [...equipped.filter((e) => e.mgear._hex !== item.mgear._hex)] // this actually removes the item if already there
      : [...equipped.filter((e) => e.mgear._hex !== item.mgear._hex), item]; // add svg

    setEquipped(newEquipped);
  };

  useEffect(() => {
    const fetchMgear = async () => {
      try {
        if (library && account) {
          let fetchedMGear;
          if (fetchAll) {
            fetchedMGear = await getAllMGear(library);
          } else {
            fetchedMGear = await getMGearForAccount(library, account);
          }
          setMgear(fetchedMGear);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    fetchMgear();
  }, [account, library]);

  return mgear ? (
    mgear.length ? (
      <div style={bottomStyle}>
        {mgear.map((mg) => (
          <MGearContainer
            onClick={() => {
              addSvg(mgearToSvg(mg.mgear, svgs));
              equip(mg);
            }}
          >
            <MGear mgear={mg.mgear} renderOnChain />
            <div style={{ flexGrow: 1 }}>{mg.name}</div>
            <div style={{ fontSize: "12px" }}>
              quality: {getGearQuality(getRarityIndex(mg.mgear), getStatTotal(mg.mgear))}
            </div>
          </MGearContainer>
        ))}
      </div>
    ) : (
      <div>You have no mgear yet.</div>
    )
  ) : (
    <div style={{ width: "100%", textAlign: "center" }}>Loading your mgear...</div>
  );
};
