import { BigNumber } from "@ethersproject/bignumber";
import {
  axe,
  bow,
  bowstaff,
  dagger,
  katana,
  muramasa,
  scimitar,
  staff,
  artifact,
  crystal,
  orb,
  trinket,
  amulet,
  gauntlets,
  heart,
  ring,
  boots,
  cape,
  sandals,
  specs,
  buckler,
  chainmail,
  crown,
  helmet,
  kiteshield,
  platebody,
  robes,
  shirt,
  pants,
  wings,
  hat,
  straps,
} from "./new-assets";
import { renderer } from "./renderer";
import {
  rarityIndexToColor,
  RarityIndex,
  getVariation,
  getIsAugmented,
  getAdjustedRarity,
  getGearType,
} from "../../utils/mgear";

const damageItems = [dagger, bow, staff, scimitar, katana, bowstaff, axe, muramasa];
const blockItems = [shirt, helmet, buckler, robes, straps, chainmail, kiteshield, platebody];
const celerityItems = [boots, pants, cape, specs, crystal, hat, sandals, wings];
const healthItems = [ring, trinket, amulet, gauntlets, orb, crown, heart, artifact];

const gearTypeToSvg = [damageItems, blockItems, healthItems, celerityItems];

export const mGearRenderer = (mgear: BigNumber, rarityIndex?: number) => {
  const rarity = rarityIndex !== undefined ? rarityIndex : getAdjustedRarity(mgear);

  const colorMask = BigNumber.from("0xFF");
  const r1 = mgear.shr(36).and(colorMask);
  const g1 = mgear.shr(44).and(colorMask);
  const b1 = mgear.shr(52).and(colorMask);
  const color1 = `rgb(${r1}, ${g1}, ${b1})`;

  const r2 = mgear.shr(60).and(colorMask);
  const g2 = mgear.shr(68).and(colorMask);
  const b2 = mgear.shr(76).and(colorMask);
  let color2 = `rgb(${r2}, ${g2}, ${b2})`;

  const r3 = mgear.shr(84).and(colorMask);
  const g3 = mgear.shr(92).and(colorMask);
  const b3 = mgear.shr(100).and(colorMask);
  let color3 = `rgb(${r3}, ${g3}, ${b3})`;

  const isAugmented = getIsAugmented(mgear);

  const r4 = mgear.shr(108).and(colorMask);
  const g4 = mgear.shr(116).and(colorMask);
  const b4 = mgear.shr(124).and(colorMask);
  let color4 = isAugmented ? `rgb(${r4}, ${g4}, ${b4})` : "none";

  if (rarity < RarityIndex.NOTEWORTHY) {
    color2 = color1;
    color3 = color1;
  } else if (rarity < RarityIndex.FABLED) {
    color3 = color1;
  }

  const colors = ["none", rarityIndexToColor[rarity], color1, color2, color3, color4];

  const variation = getVariation(mgear, rarity);
  let gearType = getGearType(mgear);
  const svg = gearTypeToSvg[gearType][variation];
  return renderer({ svg, dimensions: { x: 12, y: 12 }, colors });
};
