//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const kiteshield = [
    0, 0, 0, 0, 4, 4, 5, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 4, 2, 4, 5, 0, 0, 0, 0, 
    0, 0, 0, 0, 4, 2, 4, 5, 5, 5, 0, 0, 
    0, 0, 5, 4, 2, 2, 2, 4, 4, 5, 0, 0, 
    0, 0, 4, 2, 3, 2, 2, 3, 4, 5, 5, 5, 
    0, 0, 4, 2, 2, 1, 1, 2, 2, 4, 4, 5, 
    0, 4, 2, 2, 2, 1, 1, 2, 2, 2, 2, 4, 
    0, 4, 2, 2, 3, 2, 2, 3, 2, 4, 4, 4, 
    0, 4, 2, 3, 2, 2, 2, 2, 4, 0, 0, 0, 
    0, 4, 2, 2, 2, 2, 4, 4, 5, 0, 0, 0, 
    0, 4, 4, 4, 4, 4, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
]