//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const staff = [
    0, 0, 0, 0, 0, 0, 0, 0, 5, 3, 3, 3, 
    0, 0, 0, 0, 0, 0, 0, 0, 5, 3, 1, 3, 
    0, 0, 0, 0, 0, 0, 0, 0, 5, 3, 3, 3, 
    0, 0, 0, 0, 0, 0, 0, 0, 4, 3, 5, 5, 
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 
    0, 0, 0, 0, 0, 0, 2, 0, 0, 3, 0, 0, 
    0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 3, 0, 
    0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    4, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
]