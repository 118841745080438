import styled from "styled-components";
import { GameStates } from "../../game-states";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { useEffect } from "react";
import { download } from "../ChangingRoom/Composed";
import { Svg } from "../ChangingRoom/Composed";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 12px;

  background-color: rgba(50, 50, 50);
  padding: 12px;
  border: 4px solid rgba(80, 80, 80);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Option = styled.div<{ onClick: () => void; color?: string }>`
  cursor: pointer;
  color: ${(props) => props.color || "inherit"};
  text-align: left;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

type CallbackPayload = {
  nonce?: BigNumber | null;
  rarity?: number;
  mpunk?: number | null;
  svgs?: Svg[];
};

export type OptionType = {
  toGameState: number;
  display: string;
  color?: string;
  callback?: (
    provider: Web3Provider | undefined,
    toGameState: (toGameState: number) => void,
    payload: CallbackPayload
  ) => Promise<string | void> | void;
};

type TalkOptionsProps = {
  toGameState: (toGameState: number) => void;
  gameState: number;
  found: BigNumber | null;
  rarityIndex: number;
  stagedMPunk: number | null;
  svgs: Svg[];
};

const notConnectedCallback =
  (isConnectedState: GameStates): OptionType["callback"] =>
  (provider, toGameState) => {
    if (!provider) {
      toGameState(GameStates.NOT_CONNECTED);
    } else {
      toGameState(isConnectedState);
    }
  };

const unconnectedOptions: OptionType[] = [
  {
    toGameState: GameStates.CONNECT,
    display: "I will help. (CONNECT WALLET)",
  },
  {
    toGameState: GameStates.QUESTIONS_UNCONNECTED,
    display: "Can I ask you some questions first?",
  },
];

const introOptions: OptionType[] = [
  {
    toGameState: GameStates.MINE,
    display: "Make me some mgear.",
    callback: notConnectedCallback(GameStates.MINE),
  },
  // {
  //   toGameState: GameStates.MPUNKS_LOCKED,
  //   display: "Transmute my punk souls into mgear.",
  //   callback: notConnectedCallback(GameStates.MPUNKS_LOCKED),
  // },
  // {
  //   toGameState: GameStates.CHANGING_ROOM,
  //   display: "Take me to the changing room to try my mgear on.",
  //   callback: notConnectedCallback(GameStates.CHANGING_ROOM),
  // },
  {
    toGameState: GameStates.REQUEST_RENAME,
    display: "Rename my mgear.",
    callback: notConnectedCallback(GameStates.RENAME_PICK_MGEAR),
  },
  {
    toGameState: GameStates.BATTLE_PREPARE,
    display: "I want to fight.",
  },
  {
    toGameState: GameStates.QUESTIONS,
    display: "Can I ask you some questions?",
  },
];

const connectedOptions = [
  "Make me some mgear.",
  "Transmute my punk souls into mgear.",
  "Take me to the changing room to try my mgear on.",
  "I want to fight.",
  "Rename my mgear.",
];

const baseQuestionsOptions: OptionType[] = [
  {
    toGameState: GameStates.ASK_WHAT_IS_THIS_PLACE,
    display: "What is this place? What is special about it?",
  },
  {
    toGameState: GameStates.ASK_WHAT_ARE_MFIENDS,
    display: "What are mfiends (mineable_fiends) and how do I capture them?",
  },
  {
    toGameState: GameStates.ASK_WHAT_ARE_STATS,
    display: "What are the different stats? How does this affect my ability to capture mfiends?",
  },
  {
    toGameState: GameStates.ASK_WHAT_IS_MGEAR,
    display:
      "What is mgear (mineable_gear)? What are the different kinds and how can I obtain some?",
  },
  {
    toGameState: GameStates.ASK_WHAT_IS_MGEAR,
    display: "What are mwords (mineable_words)? What are they used for and how can I get them?",
  },
];

const questionsOptions: OptionType[] = [
  ...baseQuestionsOptions,
  {
    toGameState: GameStates.RETURN_TO_INTRO,
    display: "That's all for now.",
  },
];

const unconnectedQuestionsOptions: OptionType[] = [
  ...baseQuestionsOptions,
  {
    toGameState: GameStates.RETURN_TO_UNCONNECTED,
    display: "That's all for now.",
  },
];

const changingRoomOptions: OptionType[] = [
  {
    toGameState: GameStates.INTRO,
    display: "That looks great, let me download this.",
    callback: (_, __, payload) => {
      if (payload.svgs) {
        download(payload.svgs);
      }
    },
  },
  {
    toGameState: GameStates.INTRO,
    display: "Alright, I'm done.",
  },
];

const inscribingOptions: OptionType[] = [
  {
    toGameState: GameStates.DECLINE_MGEAR,
    display: "Ehhh... Actually I don't want it anymore. (DECLINE MGEAR)",
    color: "red",
  },
];

const renamePickingMWordsOptions: OptionType[] = [
  {
    toGameState: GameStates.DECLINE_RENAME,
    display: "Never mind, I actually like my mgear names.",
  },
];

const foundOptions: OptionType[] = [
  {
    toGameState: GameStates.MINT,
    display: "Yes please, I would love it!",
    callback: async (provider, toGameState, payload) => {
      const { nonce, rarity } = payload;
      if (!provider) throw new Error("No provider.");
      if (!nonce) throw new Error("No nonce found.");
      if (rarity === undefined) throw new Error("Rarity not specifed.");
      toGameState(GameStates.INSCRIBE_NAME_MINED);
    },
  },
  {
    toGameState: GameStates.DECLINE_MGEAR,
    display: "Hm... Could you make something else? (DECLINE MGEAR)",
    color: "red",
  },
];

const transmutedOptions: OptionType[] = [
  {
    toGameState: GameStates.MINT,
    display: "Yes please, I would love it!",
    callback: async (provider, toGameState, payload) => {
      const { mpunk } = payload;
      if (!provider) throw new Error("No provider.");
      if (!mpunk) throw new Error("No mpunk selected.");
      toGameState(GameStates.INSCRIBE_NAME_PUNK);
    },
  },
  {
    toGameState: GameStates.DECLINE_MGEAR,
    display: "Hm... Could you make something else?",
  },
];

const isMiningOptions: OptionType[] = [
  {
    toGameState: GameStates.STOP_MINING,
    display: "Uh, could you stop please?",
  },
];

const rarityToColor = {
  flimsy: "#6B7D7D",
  solid: "#8EAF9D",
  noteworthy: "#9984D4",
  extraordinary: "#FFC857",
  fabled: "#D65780",
  unreal: "#34e4ea",
};

const gearTypeOptions: OptionType[] = [
  {
    toGameState: GameStates.MINE_FLIMSY,
    display: "Normal.",
    color: rarityToColor.flimsy,
    callback: (provider, toGameState) => {
      if (!provider) {
        toGameState(GameStates.NOT_CONNECTED);
      } else {
        toGameState(GameStates.MINE_FLIMSY);
      }
    },
  },
  {
    toGameState: GameStates.MINE_SOLID,
    display: "Solid.",
    color: rarityToColor.solid,
    callback: (provider, toGameState) => {
      if (!provider) {
        toGameState(GameStates.NOT_CONNECTED);
      } else {
        toGameState(GameStates.MINE_SOLID);
      }
    },
  },
  {
    toGameState: GameStates.MINE_NOTEWORTHY,
    display: "Noteworthy.",
    color: rarityToColor.noteworthy,
    callback: (provider, toGameState) => {
      if (!provider) {
        toGameState(GameStates.NOT_CONNECTED);
      } else {
        toGameState(GameStates.MINE_NOTEWORTHY);
      }
    },
  },
  {
    toGameState: GameStates.CHOOSE_OTHER,
    display: "Extraordinary.",
    color: rarityToColor.extraordinary,
  },
  {
    toGameState: GameStates.CHOOSE_OTHER,
    display: "Fabled.",
    color: rarityToColor.fabled,
  },
  {
    toGameState: GameStates.CHOOSE_OTHER,
    display: "Unreal.",
    color: rarityToColor.unreal,
  },
];

const portalOptions: OptionType[] = [
  {
    toGameState: GameStates.BATTLE_EQUIP_ITEMS,
    display: "Equip items.",
  },
  {
    toGameState: GameStates.APPROACH_PORTAL,
    display: "Approach the portal.",
  },
];

const gameStateToOptions: OptionType[][] = [];

gameStateToOptions[GameStates.UNCONNECTED] = unconnectedOptions;
gameStateToOptions[GameStates.RETURN_TO_UNCONNECTED] = unconnectedOptions;
gameStateToOptions[GameStates.INTRO] = introOptions;
gameStateToOptions[GameStates.RETURN_TO_INTRO] = introOptions;
gameStateToOptions[GameStates.CONNECT] = introOptions;
gameStateToOptions[GameStates.AFTER_EQUIP] = introOptions;
gameStateToOptions[GameStates.NOT_CONNECTED] = introOptions;
gameStateToOptions[GameStates.REQUEST_GEAR] = gearTypeOptions;
gameStateToOptions[GameStates.FOUND] = foundOptions;
gameStateToOptions[GameStates.MINE] = isMiningOptions;
gameStateToOptions[GameStates.MINE_FLIMSY] = isMiningOptions;
gameStateToOptions[GameStates.MINE_SOLID] = isMiningOptions;
gameStateToOptions[GameStates.MINE_NOTEWORTHY] = isMiningOptions;
gameStateToOptions[GameStates.CHANGING_ROOM] = changingRoomOptions;
gameStateToOptions[GameStates.TRANSMUTED_PUNK] = transmutedOptions;
gameStateToOptions[GameStates.INSCRIBE_NAME_MINED] = inscribingOptions;
gameStateToOptions[GameStates.INSCRIBE_NAME_PUNK] = inscribingOptions;
gameStateToOptions[GameStates.RENAME_PICK_MGEAR] = renamePickingMWordsOptions;
gameStateToOptions[GameStates.RENAME_PICK_MWORD] = renamePickingMWordsOptions;

gameStateToOptions[GameStates.QUESTIONS_UNCONNECTED] = unconnectedQuestionsOptions;
gameStateToOptions[GameStates.QUESTIONS] = questionsOptions;
gameStateToOptions[GameStates.ASK_WHAT_ARE_MFIENDS] = questionsOptions;
gameStateToOptions[GameStates.ASK_WHAT_IS_THIS_PLACE] = questionsOptions;
gameStateToOptions[GameStates.ASK_WHAT_IS_MGEAR] = questionsOptions;
gameStateToOptions[GameStates.ASK_WHAT_ARE_MFIENDS] = questionsOptions;
gameStateToOptions[GameStates.ASK_WHAT_ARE_MWORDS] = questionsOptions;

gameStateToOptions[GameStates.BATTLE_PREPARE] = portalOptions;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const TalkOptions = ({
  toGameState,
  gameState,
  found,
  rarityIndex,
  stagedMPunk,
  svgs,
}: TalkOptionsProps) => {
  const { library, account } = useWeb3React<Web3Provider>();

  useEffect(() => {
    const transmutationTime = async () => {
      if (gameState === GameStates.TRANSMUTING) {
        await sleep(4200);
        toGameState(GameStates.TRANSMUTED_PUNK);
      }
    };

    transmutationTime();
  }, [gameState, toGameState]);

  return (
    <div>
      <Container>
        ~~What will you say?~~
        {gameStateToOptions[!gameStateToOptions[gameState] ? GameStates.INTRO : gameState]
          .filter((option) => !(option.display === "Connect my wallet, please." && !!account))
          .filter((option) => {
            if (account) return true;
            return !connectedOptions.includes(option.display);
          })
          .map((option) => (
            <Row>
              <div style={{ marginRight: "12px" }}>{"*"}</div>
              <Option
                onClick={async () => {
                  if (option.callback) {
                    await option.callback(library, toGameState, {
                      nonce: found,
                      rarity: rarityIndex,
                      mpunk: stagedMPunk,
                      svgs,
                    });
                  } else {
                    toGameState(option.toGameState);
                  }
                }}
                color={option.color}
              >
                {option.display}
              </Option>
            </Row>
          ))}
      </Container>
    </div>
  );
};
