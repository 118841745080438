import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";
import { GameStates } from "../../../game-states";
import { BigNumber } from "ethers";
import { attemptMint, attemptTransmutation } from "../../../web3/methods";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";

const Container = styled.div<{ used: boolean }>`
  @keyframes word-float {
    0% {
      transform: translatey(0px);
      text-shadow: 2px -12px 8px #ffffff;
    }
    50% {
      transform: translatey(-20px);
      text-shadow: 2px 12px 8px #ffffff;
    }
    100% {
      transform: translatey(0px);
      text-shadow: 2px -12px 8px #ffffff;
    }
  }
  animation: word-float 3s ease-in-out infinite;
  display: flex;
  position: relative;
  cursor: ${(props) => (props.used ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.used ? "0.5" : "1")};

  &:hover {
    opacity: 0.7;
  }
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export type MWordProps = {
  word: string;
  index: number;
  format: 0 | 1;
  used: boolean;
  setGameState: Dispatch<SetStateAction<number>>;
  gearTypeName: string;
  found: BigNumber | null;
  stagedMPunk: number | null;
  rarity: number;
};

export const MWord = ({
  word,
  index,
  gearTypeName,
  setGameState,
  format,
  found,
  stagedMPunk,
  rarity,
  used,
}: MWordProps) => {
  const { library } = useWeb3React<Web3Provider>();

  return (
    <Container
      used={used}
      onClick={async () => {
        if (!library || used) {
          return;
        }
        if (found && stagedMPunk) {
          throw new Error(
            "Both nonce and mpunk are staged - throwing error to avoid incorrect minting"
          );
        }
        if (found) {
          await attemptMint(library, found, BigNumber.from(rarity), index, format);
          setGameState(GameStates.AFTER_MINT);
        } else if (stagedMPunk) {
          await attemptTransmutation(library, stagedMPunk, index, format);
          setGameState(GameStates.AFTER_MINT);
        } else {
          throw new Error("No mgear staged for minting.");
        }
      }}
    >
      {format ? `${gearTypeName} of ${word}` : `${word} ${gearTypeName}`}
    </Container>
  );
};
