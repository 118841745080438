//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const helmet = [
    5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 
    0, 5, 5, 0, 0, 0, 0, 0, 0, 5, 5, 0, 
    0, 5, 5, 5, 3, 2, 2, 3, 5, 5, 5, 0, 
    0, 0, 5, 2, 2, 1, 1, 2, 2, 5, 0, 0, 
    0, 0, 2, 3, 2, 1, 1, 2, 3, 2, 0, 0, 
    0, 0, 4, 2, 0, 2, 2, 0, 2, 4, 0, 0, 
    0, 0, 4, 2, 0, 2, 2, 0, 2, 4, 0, 0, 
    0, 0, 4, 2, 0, 4, 4, 0, 2, 4, 0, 0, 
    0, 0, 4, 2, 0, 0, 0, 0, 2, 4, 0, 0, 
    0, 0, 4, 0, 0, 0, 0, 0, 0, 4, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
]