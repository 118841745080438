import styled, { keyframes } from "styled-components";
import SVG from "react-inlinesvg";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GameStates } from "../../../game-states";
import { renderMpunk } from "../../../web3/methods";

export const getMPunkSvg = (tokenId: number) => `https://api.mpunks.org/image/${tokenId}.svg`;

const Container = styled.div<{ used?: boolean }>`
  height: 100px;
  width: 100px;
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  animation: float 3s ease-in-out infinite;
  display: flex;
  position: relative;

  cursor: ${(props) => (props.used ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.used ? "0.4" : "1")};
  &:hover {
    opacity: ${(props) => (props.used ? "0.4" : "0.7")};
  }
`;

const auraAnimate = (color: string) => keyframes`
  0% {
    box-shadow: 0px 0px 30px 20px rgba(255, 255, 255, 0.37);
    background-color: rgba(255, 255, 255, 0.37);
  }
  50% {
    box-shadow: 0px 0px 30px 30px ${color};
    background-color: ${color};
  }
  100% {
    box-shadow: 0px 0px 30px 20px rgba(255, 255, 255, 0.37);
    background-color: rgba(255, 255, 255, 0.37);
  }
`;

const Aura = styled.div<{ color: string }>`
  opacity: 0.3;
  min-height: 100px;
  min-width: 100px;

  border-radius: 50%;
  animation: ${(props) => auraAnimate(props.color)} 3s ease-in-out infinite;
`;

const Area = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type MPunkProps = {
  tokenId: number;
  used?: boolean;
  setStagedMPunk: Dispatch<SetStateAction<number | null>>;
  setGameState: Dispatch<SetStateAction<number>>;
  renderOnChain?: boolean;
};

export const MPunk = ({
  tokenId,
  setStagedMPunk,
  setGameState,
  renderOnChain,
  used,
}: MPunkProps) => {
  const { library } = useWeb3React<Web3Provider>();
  const [svg, setSvg] = useState<string | null>();

  useEffect(() => {
    const load = async () => {
      if (renderOnChain && library && !svg) {
        const rendered = await renderMpunk(library, tokenId);
        setSvg(rendered);
      }
    };
    load();
  }, [library, renderOnChain, svg, tokenId]);

  return (
    <Container
      used={used}
      onClick={() => {
        setStagedMPunk(tokenId);
        setGameState(GameStates.TRANSMUTING);
      }}
    >
      <Area>
        <Aura color={"white"} />
      </Area>
      <Area>
        {renderOnChain ? (
          <SVG src={svg || ""} style={{ minWidth: "80px", minHeight: "80px" }} />
        ) : (
          <img
            alt="mpunk"
            src={getMPunkSvg(tokenId)}
            style={{ minWidth: "80px", height: "80px" }}
          />
        )}
      </Area>
    </Container>
  );
};
