import { createResponsiveGrid } from "./components/Grid";

type GridAreas = "dialogue" | "ethersmith" | "options" | "bottom";

const positionAbsolute = {
  position: "absolute" as "absolute",
  transition: "all 0.5s ease-in-out",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

const bottomStyle = {
  display: "grid",
  padding: "24px",
  overflow: "auto",
  gridGap: "32px",
};

const flexStyle = {
  display: "flex" as "flex",
  flexDirection: "row" as "row",
};

export const { Layout, Area } = createResponsiveGrid<GridAreas>({
  grids: [
    {
      template: {
        rows: ["3fr", "2fr"],
        columns: ["1fr", "1fr", "1fr"],
        areas: [
          ["dialogue", "ethersmith", "options"],
          ["bottom", "bottom", "bottom"],
        ],
      },
      gridStyle: { fontSize: "20px", ...positionAbsolute, transition: "all 0.5s ease" },
    },
    {
      template: {
        rows: ["1fr", "1fr", "1fr", "1fr"],
        columns: ["1fr"],
        areas: [["dialogue"], ["ethersmith"], ["options"], ["bottom"]],
      },
      gridStyle: { fontSize: "12px", overflow: "auto", ...positionAbsolute },
    },
  ],
  areaStyles: [
    {
      ethersmith: {
        ...flexStyle,
        justifyContent: "center",
        alignItems: "center",
      },
      dialogue: {
        ...flexStyle,
        justifyContent: "flex-end",
        alignItems: "center",
      },
      options: {
        ...flexStyle,
        justifyContent: "flex-start",
        alignItems: "center",
      },
      bottom: {
        ...bottomStyle,
        height: "240px",
        overflow: "visible",
      },
    },
    {
      ethersmith: {
        ...flexStyle,
        justifyContent: "center",
        alignItems: "center",
      },
      dialogue: {
        ...flexStyle,
        justifyContent: "center",
        alignItems: "center",
      },
      options: {
        ...flexStyle,
        justifyContent: "center",
        alignItems: "center",
      },
      bottom: {
        ...bottomStyle,
        height: "160px",
      },
    },
  ],
  cutoffs: [1224],
});
