//1 = black 2 = light blue 3 = brown

export const colors = ['none', '#2B2E28', '#8EF5F5', '#BA7F18']

export const dimensions = { x: 9, y: 13 }

export const computer = [
    0, 1, 1, 1, 1, 1, 1, 1, 0, 
    0, 1, 2, 2, 2, 2, 2, 1, 0, 
    0, 1, 2, 2, 2, 2, 2, 1, 0, 
    0, 1, 2, 2, 2, 2, 2, 1, 0, 
    0, 1, 1, 1, 1, 1, 1, 1, 0, 
    0, 0, 0, 0, 1, 0, 0, 0, 0, 
    3, 3, 3, 1, 1, 1, 3, 3, 3, 
    3, 3, 3, 3, 3, 3, 3, 3, 3, 
    3, 1, 1, 1, 1, 3, 3, 1, 3, 
    3, 1, 1, 1, 1, 3, 3, 1, 3, 
    3, 3, 3, 3, 3, 3, 3, 3, 3, 
    3, 0, 0, 0, 0, 0, 0, 0, 3, 
    3, 0, 0, 0, 0, 0, 0, 0, 3, 
    3, 0, 0, 0, 0, 0, 0, 0, 3, 
    3, 0, 0, 0, 0, 0, 0, 0, 3, 
]