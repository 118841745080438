//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const specs = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 4, 0, 0, 0, 0, 0, 0, 4, 
    5, 3, 3, 2, 0, 5, 3, 3, 0, 0, 2, 0, 
    3, 1, 1, 3, 0, 3, 1, 1, 3, 2, 0, 0, 
    3, 1, 1, 3, 4, 3, 1, 1, 3, 0, 0, 0, 
    0, 3, 3, 5, 0, 0, 3, 3, 5, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
]