//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const platebody = [
    5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 5, 
    3, 5, 0, 0, 0, 0, 0, 0, 0, 0, 5, 3, 
    3, 3, 5, 0, 0, 0, 0, 0, 0, 5, 3, 3, 
    3, 3, 3, 2, 0, 0, 0, 0, 2, 3, 3, 3, 
    4, 2, 2, 3, 2, 2, 2, 2, 3, 2, 2, 4, 
    4, 4, 2, 2, 3, 3, 3, 3, 2, 2, 4, 4, 
    3, 3, 2, 2, 2, 1, 1, 2, 2, 2, 3, 3, 
    0, 0, 0, 2, 2, 1, 1, 2, 2, 0, 0, 0, 
    0, 0, 0, 2, 2, 1, 1, 2, 2, 0, 0, 0, 
    0, 0, 0, 2, 2, 1, 1, 2, 2, 0, 0, 0, 
    0, 0, 0, 3, 2, 2, 2, 2, 3, 0, 0, 0, 
    0, 0, 0, 5, 3, 3, 3, 3, 5, 0, 0, 0,
]