import { Turn } from "./game-sim";
import { Player } from "./game-sim";

type Message = {
  message: string;
  color: string;
};

export type TurnMessage = {
  attack: Message;
  block?: Message;
  damage?: Message;
};

export const getMessageFromTurn = (turn: Turn, turnIndex: number): TurnMessage => {
  const isPlayer = turnIndex % 2 === 0;
  const name = isPlayer ? "You" : "The mfiend";
  const otherGuy = !isPlayer ? "You" : "The mfiend";
  console.log("turnIndex", { turnIndex, isPlayer, name, otherGuy });

  const originalDamage = turn.damage + turn.blockAmount;

  let attackMessage: string = `${name} strike${isPlayer ? "" : "s"} for ${originalDamage}!`;
  let blockMessage: string = "";

  if (turn.miss)
    return {
      attack: {
        message: `${name} strike${isPlayer ? "" : "s"}, but ${name} missed!`,
        color: "grey",
      },
    };
  if (turn.dodge)
    return {
      attack: {
        message: `${name} strike${isPlayer ? "" : "s"}, but ${otherGuy} dodged the attack!`,
        color: "yellow",
      },
    };

  if (turn.slip) attackMessage = `${name} slipped but still got a hit in for ${originalDamage}!`;
  if (turn.crit) attackMessage = `${name} landed a critical hit for ${originalDamage}!!!`;

  if (turn.block === 0) blockMessage = `${otherGuy} wasn't able to deflect any damage!`;
  if (turn.block === 1) blockMessage = `${otherGuy} dodged ${turn.blockAmount} damage!`;
  if (turn.block === 2) blockMessage = `${otherGuy} parried ${turn.blockAmount} damage!`;
  if (turn.block === 3) blockMessage = `${otherGuy} sturdily withstood ${turn.blockAmount} damage!`;

  const damageMessage = `${otherGuy} take${isPlayer ? "s" : ""} ${turn.damage} damage.`;

  return {
    attack: { message: attackMessage, color: "orange" },
    block: { message: blockMessage, color: "green" },
    damage: { message: damageMessage, color: "red" },
  };
};

export const getHealthFromTurn = (turns: Turn[], player: Player, enemy: Player, turn: number) => {
  const previousTurns = turns.slice(0, turn + 1);
  const playerDamage = previousTurns
    .filter((_, i) => i % 2 === 0)
    .reduce((acc, curr) => acc + curr.damage, 0);
  const enemyDamage = previousTurns
    .filter((_, i) => i % 2 === 1)
    .reduce((acc, curr) => acc + curr.damage, 0);

  return { player: player.vigor - enemyDamage, enemy: enemy.vigor - playerDamage };
};
