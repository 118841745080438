import styled from "styled-components";

export const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TextContainer = styled.div<{ small?: boolean }>`
  display: flex;
  margin-top: 40px;
  min-width: 240px;
  max-height: 100px;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(50, 50, 50);
  padding: 12px;
  border: 4px solid rgba(80, 80, 80);
  ${(props) => (props.small ? "font-size: 12px;" : "")}
`;

export const TextPane = styled.div<{ small?: boolean }>`
  display: flex;
  margin-top: 40px;
  min-width: 240px;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(50, 50, 50);
  padding: 12px;
  border: 4px solid rgba(80, 80, 80);
  ${(props) => (props.small ? "font-size: 12px;" : "")}
`;
