export * from "./axe.svg";
export * from "./bow.svg";
export * from "./bowstaff.svg";
export * from "./dagger.svg";
export * from "./katana.svg";
export * from "./muramasa.svg";
export * from "./scimitar.svg";
export * from "./staff.svg";

export const z = 6;
