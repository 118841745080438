//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const dagger = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0,
    0, 0, 0, 0, 0, 0, 0, 2, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 2, 4, 0, 0, 0, 0, 
    0, 0, 0, 5, 0, 2, 4, 0, 0, 0, 0, 0, 
    0, 0, 5, 3, 0, 4, 4, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 3, 0, 3, 5, 0, 0, 0, 0, 0, 
    0, 0, 3, 0, 0, 5, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,  
]