//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const robes = [
    0, 0, 0, 0, 5, 5, 5, 5, 0, 0, 0, 0, 
    0, 0, 5, 4, 2, 2, 2, 2, 4, 5, 0, 0, 
    0, 5, 4, 4, 2, 1, 1, 2, 4, 4, 5, 0, 
    5, 4, 2, 2, 4, 2, 2, 4, 2, 2, 4, 5, 
    3, 3, 3, 0, 2, 1, 1, 2, 0, 3, 3, 3, 
    0, 0, 0, 0, 2, 2, 2, 2, 0, 0, 0, 0, 
    0, 0, 0, 0, 2, 1, 1, 2, 0, 0, 0, 0, 
    0, 0, 0, 4, 2, 2, 2, 2, 4, 0, 0, 0, 
    0, 0, 4, 2, 4, 2, 2, 4, 2, 4, 0, 0, 
    0, 4, 2, 2, 2, 4, 4, 2, 2, 2, 4, 0, 
    3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 
    5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,
]