import styled from "styled-components";
import { useState, useEffect, useCallback } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { NormalizedMgear, getMGearForAccount } from "../../../web3/methods";
import {
  getEquippedMGearIds,
  equipItems,
  StatValues,
  getPlayerStats,
  getEquippedMGear,
} from "../../../web3/methods-monsters";
import { BigNumber } from "ethers";
import { MGearDisplay } from "../../MGearDisplay";
import { TextContainer } from "../../TextContainer";
import { getGearType, getRarityIndex, getStats, PlayerStats } from "../../../utils/mgear";
import { Stats } from "../../ChangingRoom/Stats";
import { Composed, Svg } from "./Composed";
import { guySvgFromAddress } from "../../svgs";
import { z as Offhand } from "../../svgs/changing-room/gear/by-slot/offhand";
import { z as Mainhand } from "../../svgs/changing-room/gear/by-slot/mainhand";
import { guyBoth, guyNoGear, guyOnlyMain, guyOnlyOff } from "../../svgs/changing-room/guy";
import {
  getHandiness,
  mgearToSvg,
  mgearToSvgWithHandiness,
  Handiness,
} from "../../ChangingRoom/mgear-to-svg";
import { BattleTalkOptions } from "../TalkOptions";
import { GameStateChanger } from "../../../utils/types";
import { GameStates } from "../../../game-states";
import { OptionType } from "../TalkOptions";
import { Ellipsis } from "../../Ellipsis";
import { Layout, Area } from "./layout";
import { AvatarColors } from "./AvatorColors";

const Inventory = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(80px, 200px));
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  padding: 24px;
  overflow: visible;
  grid-gap: 32px;
  width: 100%;
`;

const StagedContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const EquipmentSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const getEquippedItems = (
  mgearForAccount: NormalizedMgear[] | null,
  mgearEquipped: NormalizedMgear[] | null,
  mgearIds: BigNumber[] | null
): Array<(NormalizedMgear & { owned: boolean }) | null> => {
  if (mgearForAccount && mgearEquipped && mgearIds) {
    return mgearIds.map((mg) => {
      const ownedMgear = mgearForAccount.find((m) => m.id._hex === mg._hex);
      if (ownedMgear) return { ...ownedMgear, owned: true };

      const equipped = mgearEquipped.find((m) => m.id._hex === mg._hex);
      if (equipped) return { ...equipped, owned: false };

      return null;
    });
  }
  return [null, null, null, null];
};

const typeMap = ["ruin", "guard", "vigor", "celerity"];

type StagedMGear = NormalizedMgear & { new: boolean; owned: boolean };

const didChangeMgear = (
  staged: Array<StagedMGear | null>,
  mgearEquipped: NormalizedMgear[]
): boolean => {
  const equippedIds = mgearEquipped.map((mg) => mg.id._hex).sort();
  const stagedIds = staged
    .map((mg) => (mg ? mg.id._hex : null))
    .filter((a) => !!a)
    .sort();
  return JSON.stringify(equippedIds) !== JSON.stringify(stagedIds);
};

const EquippedMgear = ({
  equippedGear,
  index,
  onClick,
}: {
  equippedGear: Array<StagedMGear | null>;
  index: number;
  onClick: () => void;
}) => {
  const mgear = equippedGear[index];
  return (
    <EquipmentSlotContainer>
      {mgear !== null ? (
        <div
          style={
            mgear.owned
              ? { padding: "8px", border: "2px solid transparent" }
              : { border: "2px solid red", padding: "8px", borderRadius: "4px" }
          }
        >
          <MGearDisplay mgear={mgear} onClick={onClick} animate={false} />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <TextContainer small style={{ width: "50%", minWidth: "50px" }}>
            no item equipped.
          </TextContainer>
        </div>
      )}
      <TextContainer small style={{ width: "80%", minWidth: "100px" }}>
        {typeMap[index]} item
      </TextContainer>
    </EquipmentSlotContainer>
  );
};

const fadeInStyle = { animation: "fade-in 0.8s ease-in-out" };

const stagedMgearToMgear = (mgear: Array<StagedMGear | null>): BigNumber[] => {
  return mgear.map((mg) => mg?.mgear).filter((m) => !!m) as BigNumber[];
};

export const BattleEquip = ({ setGameState }: GameStateChanger) => {
  const { library, account } = useWeb3React<Web3Provider>();

  const [svgs, setSvgs] = useState<Svg[]>([guySvgFromAddress(BigNumber.from(account))]);

  // mgear they own
  const [mgearForAccount, setMgearForAccount] = useState<NormalizedMgear[] | null>(null);
  // mgear they have equipped
  const [mgearEquipped, setMgearEquipped] = useState<NormalizedMgear[] | null>(null);
  const [equippedMgearIds, setEquippedMgearIds] = useState<BigNumber[] | null>(null);
  const [stagedMgear, setStagedMgear] = useState<Array<StagedMGear | null>>([
    null,
    null,
    null,
    null,
  ]);

  const addSvg = useCallback(
    (svg: Svg, handiness?: Handiness) => {
      setSvgs((prevSvgs) => {
        const isSvgTheSame = (svg1: Svg, svg2: Svg) =>
          svg1.z === svg2.z && svg1.svg.join("") === svg2.svg.join("");

        let processed = [...prevSvgs];
        console.log("PROCESSED 1", processed);
        const toUnequip = processed.some((s) => isSvgTheSame(s, svg));
        console.log("toUnequip", toUnequip);

        if (toUnequip) {
          processed = processed.filter((s) => !isSvgTheSame(s, svg));
        } else {
          // remove same slot if there
          processed = processed.filter((s) => {
            console.log("same slot", s.group !== svg.group, s, svg);
            if (!s.group) return true;
            return s.group !== svg.group;
          });

          // add our new svg
          processed = [...processed, svg];
        }
        console.log("PROCESSED 2", processed);

        // infer handiness from the new setup
        let hasMainhand = !!processed?.some((a) => a.z === Mainhand);
        let hasOffhand = !!processed?.some((a) => a.z === Offhand);

        if (handiness) {
          hasMainhand = handiness.hasMainhand;
          hasOffhand = handiness.hasOffhand;
        }

        console.log({ hasMainhand, hasOffhand });
        // change main guy
        if (hasMainhand) {
          if (hasOffhand) processed[0] = { ...processed[0], svg: guyBoth };
          else processed[0] = { ...processed[0], svg: guyOnlyMain };
        } else if (hasOffhand) {
          processed[0] = { ...processed[0], svg: guyOnlyOff };
        } else {
          processed[0] = { ...processed[0], svg: guyNoGear };
        }

        console.log("PROCESSED 3", processed);

        // let's go back and make sure all the svg handiness is correct
        processed = processed.map((svg) => {
          if (["robes", "gauntlets", "ring"].includes(svg?.id || "")) {
            return mgearToSvgWithHandiness(svg.mgear, { hasMainhand, hasOffhand });
          }
          return svg;
        });

        console.log({ processed });

        return processed;
      });
    },
    [setSvgs]
  );

  const [stats, setStats] = useState<StatValues | null>(null);

  useEffect(() => {
    const load = async () => {
      if (library && account && !stats) {
        const playerStats = await getPlayerStats(library!, account!);
        setStats(playerStats);
      }
    };

    load();
  }, [library, account, stats]);

  useEffect(() => {
    if (mgearForAccount && equippedMgearIds) {
      const equipped = getEquippedItems(mgearForAccount, mgearEquipped, equippedMgearIds);
      setStagedMgear(equipped.map((e) => (e ? { ...e, new: false } : null)));

      const handiness = getHandiness(equipped.map((e) => e?.mgear || null));
      const mgearSvgs = equipped.map((item) =>
        item ? mgearToSvgWithHandiness(item.mgear, handiness) : null
      );

      mgearSvgs.forEach((mgearSvg) => {
        if (mgearSvg) {
          addSvg(mgearSvg, handiness);
        }
      });
    }
  }, [mgearForAccount, equippedMgearIds, mgearEquipped, addSvg]);

  useEffect(() => {
    const fetchMgear = async () => {
      try {
        if (library && account) {
          const fetchedMGear = await getMGearForAccount(library, account);
          setMgearForAccount(fetchedMGear);

          const equippedMgear = await getEquippedMGear(library, account);
          console.log({ equippedMgear });
          setMgearEquipped(equippedMgear);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    fetchMgear();
  }, [account, library]);

  useEffect(() => {
    const fetchMgear = async () => {
      try {
        if (library && account) {
          const equippedIds = await getEquippedMGearIds(library, account);
          setEquippedMgearIds(equippedIds);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    fetchMgear();
  }, [account, library]);

  const stageGear = (mgear: NormalizedMgear) => {
    const index = getGearType(mgear.mgear);
    const svg = mgearToSvg(mgear.mgear, svgs);
    setStagedMgear((prev) => {
      const newStaged = [...prev];
      newStaged[index] = { ...mgear, new: true, owned: true };
      const mgears = stagedMgearToMgear(newStaged);
      addSvg(svg, getHandiness(mgears));
      return newStaged;
    });
  };

  const unstageGear = (index: number) => {
    const mgear = stagedMgear[index];
    if (mgear) {
      const svg = mgearToSvg(mgear.mgear, svgs);
      addSvg(svg);
    }

    setStagedMgear((prev) => {
      const newStaged = [...prev];
      newStaged[index] = null;
      return newStaged;
    });
  };

  const getItemToEquip = (index: number) => {
    return stagedMgear[index]?.id.toNumber() || 0;
  };

  const stagedStats: PlayerStats = stagedMgear
    .map((mgear) =>
      mgear
        ? (getStats(mgear?.mgear, getRarityIndex(mgear.mgear)) as PlayerStats)
        : { ruin: 0, vigor: 0, guard: 0, celerity: 0 }
    )
    .reduce(
      (acc, curr) =>
        ({
          ruin: acc.ruin + (curr.ruin || 0),
          guard: acc.guard + (curr.guard || 0),
          vigor: acc.vigor + (curr.vigor || 0),
          celerity: acc.celerity + (curr.celerity || 0),
        } as PlayerStats),
      { ruin: 1, vigor: 10, guard: 0, celerity: 0 }
    );

  let options: OptionType[] = [
    {
      toGameState: GameStates.BATTLE_PREPARE,
      display: "Go back.",
    },
  ];

  // if we changed or removed gear
  if (stagedMgear && mgearEquipped && didChangeMgear(stagedMgear, mgearEquipped)) {
    options = [
      ...options,
      {
        display: "Commit to this gear set.",
        color: "yellow",
        callback: async () => {
          await equipItems(library!, {
            ruinItemId: getItemToEquip(0),
            guardItemId: getItemToEquip(1),
            vigorItemId: getItemToEquip(2),
            celerityItemId: getItemToEquip(3),
          });
          setGameState(GameStates.AFTER_EQUIP);
        },
      },
    ];
  }

  return (
    <Layout style={fadeInStyle}>
      <Area name="character">
        <div style={{ display: "flex", width: "70%", marginBottom: "20px" }}>
          <Composed svgs={svgs} width="100%" height="100%" />
        </div>
        <BattleTalkOptions setGameState={setGameState} options={options} />
        {stats && (
          <Stats
            {...stats}
            ruinDiff={mgearForAccount ? stagedStats.ruin - stats.ruin : 0}
            guardDiff={mgearForAccount ? stagedStats.guard - stats.guard : 0}
            vigorDiff={mgearForAccount ? stagedStats.vigor - stats.vigor : 0}
            celerityDiff={mgearForAccount ? stagedStats.celerity - stats.celerity : 0}
            hideGearScore
            loading={!mgearForAccount}
          />
        )}
        <TextContainer style={{ fontSize: "12px", marginBottom: "12px" }}>
          Your avatar colors are derived from your wallet. Change wallets to change avatars.
        </TextContainer>
        <AvatarColors />
      </Area>
      {mgearForAccount ? (
        <Area name="inventory">
          <StagedContainer>
            <EquippedMgear equippedGear={stagedMgear} index={0} onClick={() => unstageGear(0)} />
            <EquippedMgear equippedGear={stagedMgear} index={1} onClick={() => unstageGear(1)} />
            <EquippedMgear equippedGear={stagedMgear} index={2} onClick={() => unstageGear(2)} />
            <EquippedMgear equippedGear={stagedMgear} index={3} onClick={() => unstageGear(3)} />
          </StagedContainer>
          <Inventory>
            {console.log({ stagedMgear })}
            {mgearForAccount ? (
              mgearForAccount.length > 0 ? (
                mgearForAccount.map((mgear) => (
                  <MGearDisplay
                    mgear={mgear}
                    onClick={() => stageGear(mgear)}
                    showStats
                    animate={!stagedMgear?.find((smg) => smg?.id._hex === mgear.id._hex)}
                    disabled={!!stagedMgear?.find((smg) => smg?.id._hex === mgear.id._hex)}
                  />
                ))
              ) : (
                <TextContainer>
                  You have no mgear. Have the Ethersmith craft you gear, or buy gear on secondary
                  marketplaces.
                </TextContainer>
              )
            ) : (
              <div>loading...</div>
            )}
          </Inventory>
        </Area>
      ) : (
        <Area name="inventory">
          Loading your inventory and equipped gear
          <Ellipsis />
        </Area>
      )}
    </Layout>
  );
};
