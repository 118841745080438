import { Dispatch, SetStateAction } from "react";
import { useEagerWithRemoteFallback, injectedConnector } from "../../../web3";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { GameStates } from "../../../game-states";

const Container = styled.div`
  margin-top: 16px;
  min-width: 300px;
  max-width: 300px;
  text-align: left;
  background-color: rgba(50, 50, 50);
  padding: 12px;
  border: 4px solid rgba(80, 80, 80);
`;

type ConnectionProps = {
  setGameState: Dispatch<SetStateAction<number>>;
};

export const Connection = ({ setGameState }: ConnectionProps) => {
  const [attempted, setAttempted] = useState(false);
  const {
    status,
    provider: { account, activate },
  } = useEagerWithRemoteFallback();

  useEffect(() => {
    const connect = async () => {
      if ((!account || !status) && !attempted) {
        try {
          await activate(injectedConnector);
        } catch (e: any) {
        } finally {
          setAttempted(true);
        }
      }
    };
    connect();
  }, [account, status, activate, attempted]);

  useEffect(() => {
    if (attempted && !account) setGameState(GameStates.NO_WALLET);
  });

  return (
    <Container style={{ fontSize: "12px", marginTop: "12px" }}>Connected with: {account}</Container>
  );
};
