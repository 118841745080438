import styled, { keyframes } from "styled-components";
import { Dispatch, SetStateAction } from "react";
import { BigNumber } from "ethers";
import { Portal } from "../../Portal";
import { Monster } from "../Monster";
import { TextContainer } from "../../TextContainer";
import { GameStates } from "../../../game-states";
import { BattleTalkOptions } from "../TalkOptions";

type WaitAtPortalProps = {
  hashRate: number;
  elapsed: number;
  found: BigNumber | null;
  setGameState: Dispatch<SetStateAction<number>>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const comeInAnimate = keyframes`
  0% {
    width: 20%;
    height: 20%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
`;

const ComeIn = styled.div`
  width: 100%;
  animation: ${comeInAnimate} 1s ease-in-out;
`;

export const WaitAtPortal = ({ hashRate, elapsed, found, setGameState }: WaitAtPortalProps) => {
  return !found ? (
    <Container>
      <TextContainer>
        You wait at the portal, attracting otherwordly beings with your energy. <br />
        <br />
        You're emitting approximately {hashRate} units of mineable radiation.
        <br />
        You've been waiting by the portal for {elapsed} seconds.
      </TextContainer>
      <BattleTalkOptions
        setGameState={setGameState}
        options={[
          { display: "Retreat from the wormhole.", toGameState: GameStates.BATTLE_PREPARE },
        ]}
      />
    </Container>
  ) : (
    <Container>
      <div style={{ width: "25%", height: "50%" }}>
        <Monster nonce={found} />
      </div>
      <TextContainer>
        A foe bursts from the portal, looking to feed on your computational energy.
      </TextContainer>
      <BattleTalkOptions
        setGameState={setGameState}
        options={[
          { display: "Challenge to a contest of proof-of-power", toGameState: GameStates.BATTLE },
          { display: "Run!", toGameState: GameStates.BATTLE_PREPARE },
        ]}
      />
    </Container>
  );
};
