import { Dispatch, SetStateAction } from "react";
import { GameStates } from "../../../game-states";
import { Layout, Area } from "../../../layout";
import { Blacksmith } from "../../Blacksmith";
import { TextContainer } from "../../TextContainer";
import styled from "styled-components";

type BattleProps = {
  gameState: number;
  setGameState: Dispatch<SetStateAction<number>>;
};

const Hover = styled.div`
  @keyframes bs-float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  animation: bs-float 4s ease-in-out infinite;
  width: 70%;
`;

export const Backup = ({ gameState, setGameState }: BattleProps) => {
  setTimeout(() => setGameState(GameStates.AFTER_BACKUP), 4000);

  return (
    <Layout>
      <Area name="ethersmith" style={{ flexDirection: "column" }}>
        <Hover>
          <Blacksmith gameState={gameState} />
        </Hover>
        <TextContainer>
          With your Ether and the mfiends's weakened state, I should be able to capture this monster
          - leave it to me!
        </TextContainer>
      </Area>
    </Layout>
  );
};
