import { getMessageFromTurn, TurnMessage } from "../../../../utils/battle";
import { TextContainer } from "../../../TextContainer";
import { Turn } from "../../../../utils/game-sim";
import { GameStateChanger } from "../../../../utils/types";
import { GameStates } from "../../../../game-states";
import { mint } from "../../../../web3/methods-monsters";
import { BigNumber } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { BattleTalkOptions } from "../../TalkOptions";

type BattleProgressProps = GameStateChanger & {
  turns: Turn[];
  result: boolean;
  finished: boolean;
  nonce: BigNumber;
};

const TurnBlock = ({ turn }: { turn: TurnMessage }) => (
  <TextContainer style={{ display: "flex", flexDirection: "column" }}>
    <div style={{ color: turn.attack.color }}>{turn.attack.message}</div>
    {turn.block && <div style={{ color: turn.block.color }}>{turn.block.message}</div>}
    {turn.damage && <div style={{ color: turn.damage.color }}>{turn.damage.message}</div>}
  </TextContainer>
);

const ResultBlock = ({
  result,
  setGameState,
  nonce,
  wasTieBreak,
}: {
  result: boolean;
  setGameState: GameStateChanger["setGameState"];
  nonce: BigNumber;
  wasTieBreak: boolean;
}) => {
  const { library } = useWeb3React<Web3Provider>();

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {wasTieBreak && (
        <TextContainer>
          {result
            ? "You see an opening in the mfiend's defense - you strike with all your might, incapacitating the creature!"
            : "You feel all your energy drained, and lacking the strength to continue."}
        </TextContainer>
      )}
      <TextContainer style={{ display: "flex", flexDirection: "column" }}>
        {result
          ? "YOU HAVE PREVAILED. QUICK - CAPTURE THE FIEND!"
          : "OH DEAR. YOU HAVE BEEN DEFEATED."}
      </TextContainer>
      {result ? (
        <BattleTalkOptions
          options={[
            {
              display: "Capture! (MINT THE MFIEND)",
              callback: async () => {
                await mint(library!, nonce);
                setGameState(GameStates.AFTER_CAPTURE);
              },
            },
            {
              display: "Not worth it. (RETREAT WITHOUT MINTING)",
              color: "red",
              toGameState: GameStates.BATTLE_PREPARE,
            },
          ]}
          setGameState={setGameState}
        />
      ) : (
        <BattleTalkOptions
          options={[
            {
              display: "Retreat.",
              toGameState: GameStates.BATTLE_PREPARE,
            },
            {
              display: "Call the Ethersmith for backup! (Pay 0.08 ETH to mint the mfiend)",
              callback: async () => {
                await mint(library!, nonce, true);
                setGameState(GameStates.CALL_ETHERSMITH_FOR_BACKUP);
              },
            },
          ]}
          setGameState={setGameState}
        />
      )}
    </div>
  );
};

export const BattleProgress = ({
  result,
  turns,
  finished,
  setGameState,
  nonce,
}: BattleProgressProps) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!finished &&
        turns.slice(turns.length - 1, turns.length).map((turn, i) => (
          <div style={{ display: "flex", flexDirection: "column" }} key={i}>
            {console.log({ turn, i })}
            <TurnBlock turn={getMessageFromTurn(turn, turns.length - 1)} />
          </div>
        ))}
      {finished && (
        <ResultBlock
          result={result}
          setGameState={setGameState}
          nonce={nonce}
          wasTieBreak={turns.length >= 10}
        />
      )}
    </div>
  );
};
