import { Composed, Svg } from "../../BattleEquip/Composed";
import { HealthBar } from "../HealthBar";
import styled from "styled-components";

const Flip = styled.div`
  transform: scaleX(-1);
`;

export const Challenger = ({
  health,
  damageTaken,
  svgs,
}: {
  health: number;
  damageTaken: number;
  svgs: Svg[];
}) => {
  return (
    <div>
      <HealthBar total={health} damageTaken={damageTaken} />
      <Flip>
        <Composed svgs={svgs} />
      </Flip>
    </div>
  );
};
