import styled from "styled-components";
import { TextContainer } from "../../TextContainer";

type StatsProps = {
  celerity: number;
  ruin: number;
  vigor: number;
  guard: number;
  hideGearScore?: boolean;
  celerityDiff?: number;
  ruinDiff?: number;
  vigorDiff?: number;
  guardDiff?: number;
  loading?: boolean;
};

const Container = styled.div`
  display: flex;
  gap: 8px;
`;

const gearScores = ["peasant", "sturdy", "formidable", "powerful", "triumphant", "godlike"];

const cutoffs = [32, 64, 96, 128, 150];

const getGearScore = (total: number) => {
  const index = cutoffs.findIndex((cutoff) => cutoff >= total);
  return gearScores[index !== undefined ? index : 5];
};

const formatDiff = (diff: number) =>
  diff > 0 ? (
    <span style={{ color: "green" }}>+{diff}</span>
  ) : (
    <span style={{ color: "red" }}>{diff}</span>
  );

const getText = (num: number, diff: number, loading?: boolean) => {
  if (loading) return "--";
  if (diff)
    return (
      <>
        {num} {formatDiff(diff)}
      </>
    );
  return num;
};

export const Stats = ({
  celerity,
  ruin,
  vigor,
  guard,
  hideGearScore,
  celerityDiff = 0,
  ruinDiff = 0,
  guardDiff = 0,
  vigorDiff = 0,
  loading,
}: StatsProps) => (
  <Container>
    <TextContainer small style={{ minWidth: "100px" }}>
      ruin: {getText(ruin, ruinDiff, loading)}
    </TextContainer>
    <TextContainer small style={{ minWidth: "100px" }}>
      guard: {getText(guard, guardDiff, loading)}
    </TextContainer>
    <TextContainer small style={{ minWidth: "100px" }}>
      vigor: {getText(vigor, vigorDiff, loading)}
    </TextContainer>
    <TextContainer small style={{ minWidth: "100px" }}>
      celerity: {getText(celerity, celerityDiff, loading)}
    </TextContainer>
    {!hideGearScore && (
      <TextContainer small style={{ minWidth: "100px" }}>
        GEAR SCORE: {loading ? "--" : getGearScore(ruin + guard + vigor + celerity)}
      </TextContainer>
    )}
  </Container>
);
