export enum GameStates {
  UNCONNECTED,
  INTRO,
  REQUEST_GEAR,
  REQUEST_COLLOSEUM,
  REQUEST_TOWN,
  REQUEST_MORE_INFO,
  REQUEST_RENAME,
  RENAME_PICK_MGEAR,
  RENAME_PICK_MWORD,
  DECLINE_RENAME,
  CHOOSE_FLIMSY,
  CHOOSE_SOLID,
  CHOOSE_NOTEWORTHY,
  CHOOSE_OTHER,
  CONNECT,
  MINE_FLIMSY,
  MINE_SOLID,
  MINE_NOTEWORTHY,
  FOUND,
  MINT,
  AFTER_MINT,
  AFTER_RENAME,
  DECLINE_MGEAR,
  STOP_MINING,
  ASK_TO_TRANSMUTE,
  TRANSMUTING,
  TRANSMUTED_PUNK,
  NOT_CONNECTED,
  CHANGING_ROOM,
  RETURN_TO_INTRO,
  QUESTIONS,
  WHERE_COMMUNITY,
  WHERE_PUNKS,
  WHERE_WORDS,
  WHAT_IS_THIS_PLACE,
  WHO_ARE_YOU,
  WHAT_ARE_MGEAR,
  SO_YOURE_THE_ONE,
  WHAT_MGEAR_KINDS,
  WHAT_MGEAR_STATS,
  INSCRIBE_NAME_MINED,
  INSCRIBE_NAME_PUNK,
  NO_WALLET,
  MPUNKS_LOCKED,
  MINE,
  BATTLE,
  BATTLE_EQUIP_ITEMS,
  BATTLE_PREPARE,
  APPROACH_PORTAL,
  MONSTER_FOUND,
  AFTER_CAPTURE,
  AFTER_BACKUP,
  AFTER_EQUIP,
  CALL_ETHERSMITH_FOR_BACKUP,
  ASK_WHAT_IS_THIS_PLACE,
  ASK_WHAT_ARE_MFIENDS,
  ASK_WHAT_ARE_STATS,
  ASK_WHAT_IS_MGEAR,
  ASK_WHAT_ARE_MWORDS,
  RETURN_TO_UNCONNECTED,
  QUESTIONS_UNCONNECTED,
}
