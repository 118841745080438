import { BigNumber } from "ethers";
import { hash } from "../components/Mine/mine";
import { MINEABLEGEAR_ADDR } from "../web3";

export const getGameResult = (nonce: BigNumber) => {
  console.log({ MINEABLEGEAR_ADDR });
  return hash({ address: BigNumber.from(MINEABLEGEAR_ADDR), nonce });
};

// USE CHAIN GENERATION

// export const generateEnemy = (challenger: StatValues, seed: BigNumber): StatValues => {
//   return {
//     ruin: challenger.ruin + seed.shr(32).and("0x7").toNumber(),
//     guard: challenger.guard + seed.shr(35).and("0x7").toNumber(),
//     vigor: challenger.vigor + seed.shr(38).and("0x7").toNumber(),
//     celerity: challenger.celerity + seed.shr(41).and("0x7").toNumber(),
//   };
// };

export const affinityToName = [
  "Prototypical",
  "Vibrant",
  "Plasmic",
  "Fluid",
  "Organic",
  "Umbral",
  "Luminous",
  "Abyssal",
];
