import { Web3Provider } from "@ethersproject/providers";
import { MFiends__factory as MMonsters__factory } from "../contracts/mineablemonsters";
import { MGear__factory } from "../contracts/mineablegear";
import { BigNumber, utils } from "ethers";
import { MINEABLEMONSTERS_ADDR, MINEABLEGEAR_ADDR } from ".";
import { hash } from "../components/Mine/mine";
import { Attributes, NormalizedMgear } from "./methods";
import { affinityToName } from "../utils/mmonsters";

let cache = {};

export const equipItems = async function (
  lib: Web3Provider,
  {
    ruinItemId,
    vigorItemId,
    guardItemId,
    celerityItemId,
  }: { ruinItemId: number; vigorItemId: number; guardItemId: number; celerityItemId: number }
): Promise<string> {
  const contract = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);
  try {
    const signer = lib.getSigner();

    const tx = await contract
      .connect(signer)
      .equipItems(ruinItemId, guardItemId, vigorItemId, celerityItemId);

    return tx.hash;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export type StatValues = { ruin: number; guard: number; vigor: number; celerity: number };

export const getPlayerStats = async (
  lib: Web3Provider,
  account: string
): Promise<StatValues & { init: boolean }> => {
  const contract = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);
  try {
    let stats = {
      ruin: BigNumber.from(0),
      guard: BigNumber.from(0),
      vigor: BigNumber.from(0),
      celerity: BigNumber.from(0),
    };

    let init = false;

    try {
      stats = await contract.stats(account);
      console.log("hiiii", { stats });
      init = stats.ruin.toNumber() > 0;
    } catch (e: any) {
      console.log(e.message);
    }

    return {
      ruin: stats.ruin.toNumber() || 1,
      guard: stats.guard.toNumber(),
      vigor: stats.vigor.toNumber() || 10,
      celerity: stats.celerity.toNumber(),
      init,
    };
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const assertValidGear = async (
  lib: Web3Provider,
  account: string,
  init?: boolean
): Promise<boolean | null> => {
  if (init === false) return null;

  const contract = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);

  try {
    const stats = await getPlayerStats(lib, account);
    if (stats.vigor > 0) {
      await contract.assertValidGear(account);
    }
    return true;
  } catch (e: any) {
    console.log("FAILED ASSERTION:", e.message);
    return false;
  }
};

export const mint = async function (
  lib: Web3Provider,
  nonce: BigNumber,
  callForHelp: boolean = false
): Promise<string> {
  const contract = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);
  try {
    const signer = lib.getSigner();
    const value = callForHelp ? utils.parseEther("0.05") : 0;

    const tx = await contract.connect(signer).mint(nonce.toHexString(), callForHelp, {
      value,
    });

    return tx.hash;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

const getCharacteristicSeed = ({ nonce }: { account: BigNumber; nonce: BigNumber }) =>
  hash({ address: BigNumber.from(MINEABLEMONSTERS_ADDR), nonce });

export const renderData = async function (
  lib: Web3Provider,
  account: string,
  nonce: BigNumber
): Promise<{ svg: string; affinity: string; winged: boolean; runed: boolean }> {
  const contract = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);
  try {
    const signer = lib.getSigner();

    const tokenData = hash({ address: BigNumber.from(account), nonce }).and("0xFFFFFFFF");
    const winged = tokenData.shr(24).and(0xf).toNumber() < 2;
    const runed = tokenData.shr(28).and(0xf).toNumber() < 2;

    const characteristicsSeed = hash({
      address: BigNumber.from(MINEABLEMONSTERS_ADDR),
      nonce: tokenData,
    });

    const affinityData = await contract.connect(signer).getAffinity(characteristicsSeed);

    const palette0 = await contract.getPalette(characteristicsSeed, affinityData);
    console.log("palette0", palette0);
    const palette1 = await contract.getPalette(
      hash({ address: BigNumber.from(MINEABLEMONSTERS_ADDR), nonce: palette0 }),
      affinityData
    );
    const palette2 = await contract.getPalette(
      hash({ address: BigNumber.from(MINEABLEMONSTERS_ADDR), nonce: palette1 }),
      affinityData
    );

    const affinity = affinityToName[affinityData.toNumber()] || "unknown";
    console.log("renderiing data...");
    const svg = await contract
      .connect(signer)
      .renderData(tokenData, [palette0, palette1, palette2], "");

    return { svg, affinity, winged, runed };
  } catch (e: any) {
    console.log("FAILED TO RENDER DATA:", e.message);
    throw e;
  }
};

export const getEquippedMGear = async function (
  lib: Web3Provider,
  account: string
): Promise<NormalizedMgear[]> {
  const mgear = MGear__factory.connect(MINEABLEGEAR_ADDR, lib);
  try {
    const mgearIds = await getEquippedMGearIds(lib, account);

    const result: NormalizedMgear[] = await Promise.all(
      mgearIds.map(async (mgearId, i) => {
        const mgearData = await mgear.tokenIdToMGear(mgearId);
        const data = await mgear.renderData(mgearData);
        const base64 = data.replace("data:application/json;base64,", "");
        const decoded = atob(base64);
        const parsed = JSON.parse(decoded);

        return {
          mgear: mgearData,
          name: parsed.name,
          attributes: parsed.attributes,
          id: mgearId,
        };
      })
    );

    console.log(result);

    return result.filter((mg) => mg.id.toNumber() !== 0);
  } catch (e: any) {
    console.log(e);
    return [];
  }
};

export const getEquippedMGearIds = async function (
  lib: Web3Provider,
  account: string
): Promise<BigNumber[]> {
  const mmonsters = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);

  try {
    console.log("GETTING", MINEABLEMONSTERS_ADDR, account);
    const items = await mmonsters.getEquippedItems(account);
    console.log(items);
    return items;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const generateEnemy = async function (
  lib: Web3Provider,
  account: string,
  nonce: BigNumber
): Promise<StatValues> {
  const mmonsters = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);

  try {
    const resultHash = hash({ address: BigNumber.from(account), nonce });
    const tokenData = resultHash.and(BigNumber.from("0xFFFFFFFF"));
    const affinityHash = hash({ address: BigNumber.from(MINEABLEMONSTERS_ADDR), nonce: tokenData });
    const stats = await mmonsters.stats(account);
    const affinity = await mmonsters.getAffinity(affinityHash);
    const enemy = await mmonsters.generateEnemy(resultHash, stats, affinity);

    const enemyStats = {
      ruin: enemy.ruin.toNumber(),
      guard: enemy.guard.toNumber(),
      vigor: enemy.vigor.toNumber(),
      celerity: enemy.celerity.toNumber(),
    };
    return enemyStats;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

export const tokenURI = async function (
  lib: Web3Provider,
  account: string,
  id: number
): Promise<string> {
  const mmonsters = MMonsters__factory.connect(MINEABLEMONSTERS_ADDR, lib);

  try {
    const uri = await mmonsters.tokenURI(id);
    console.log({ uri });
    return uri;
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};

// games comes from mineable_gear address
export const getGame = (nonce: BigNumber) =>
  hash({ address: BigNumber.from(MINEABLEGEAR_ADDR), nonce });
