import { useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { getMWordsForAccount } from "../../web3/methods";
import { MWord, MWordProps } from "./MWord";
import { getMGearTypeName, getAdjustedRarity, rarityIndexToRarityName } from "../../utils/mgear";
import { TextContainer, Center } from "../TextContainer";
import { bottomStyle } from "../../utils/mgear";

export const MWordsToRename = ({
  stagedMGearToRename,
  setGameState,
}: Omit<MWordProps, "word" | "index" | "format" | "gearTypeName" | "rarity" | "used">) => {
  const [mwords, setMwords] = useState<{ index: number; word: string; used: boolean }[] | null>(
    null
  );
  const { library, account } = useWeb3React<Web3Provider>();

  useEffect(() => {
    const fetchMWords = async () => {
      try {
        if (library && account) {
          const fetchedMWords = await getMWordsForAccount(library, account);
          setMwords(fetchedMWords);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    fetchMWords();
  }, [account, library, stagedMGearToRename]);

  if (mwords && mwords.length === 0) {
    return (
      <Center>
        <MWord
          {...{
            rarity: getAdjustedRarity(stagedMGearToRename.mgear),
            word: rarityIndexToRarityName[getAdjustedRarity(stagedMGearToRename.mgear)],
            index: 0,
            used: false,
            gearTypeName: getMGearTypeName(stagedMGearToRename.mgear),
            setGameState,
            format: 0,
            stagedMGearToRename,
          }}
        />
      </Center>
    );
  }

  return stagedMGearToRename && mwords ? (
    <>
      <div style={bottomStyle}>
        {account && (
          <MWord
            {...{
              rarity: getAdjustedRarity(stagedMGearToRename.mgear),
              word: `I don't want to name my gear. (Your mgear will be called "${
                rarityIndexToRarityName[getAdjustedRarity(stagedMGearToRename.mgear)]
              }`,
              index: 0,
              used: false,
              gearTypeName: `${getMGearTypeName(stagedMGearToRename.mgear)}")`,
              setGameState,
              format: 0,
              stagedMGearToRename,
            }}
          />
        )}
        {mwords.map(({ word, index, used }) => (
          <>
            <MWord
              {...{
                rarity: getAdjustedRarity(stagedMGearToRename.mgear),
                word,
                index,
                used,
                gearTypeName: getMGearTypeName(stagedMGearToRename.mgear),
                setGameState,
                format: 0,
                stagedMGearToRename,
              }}
            />
            <MWord
              {...{
                rarity: getAdjustedRarity(stagedMGearToRename.mgear),
                word,
                index,
                used,
                gearTypeName: getMGearTypeName(stagedMGearToRename.mgear),
                setGameState,
                format: 1,
                stagedMGearToRename,
              }}
            />
          </>
        ))}
      </div>
    </>
  ) : (
    <Center>
      <TextContainer>Loading your mwords...</TextContainer>
    </Center>
  );
};
