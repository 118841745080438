import styled from "styled-components";
import { GameStateChanger } from "../../../utils/types";
import { GameStates } from "../../../game-states";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  gap: 12px;

  background-color: rgba(50, 50, 50);
  padding: 12px;
  border: 4px solid rgba(80, 80, 80);
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Option = styled.div<{ onClick: () => void; color?: string; disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => props.color || "inherit"};
  text-align: left;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export type OptionType = {
  toGameState?: any;
  display: string;
  callback?: () => void;
  color?: string;
  disabled?: boolean;
};

export const BattleTalkOptions = ({
  setGameState,
  options,
}: GameStateChanger & { options: OptionType[] }) => (
  <Container>
    ~~What will you do?~~
    {options.map((option) => (
      <Row>
        <div style={{ marginRight: "12px" }}>{"*"}</div>
        <Option
          disabled={option.disabled}
          onClick={() => {
            if (option.disabled) return;
            if (option.toGameState !== undefined) setGameState(option.toGameState);
            if (option.callback) option.callback();
          }}
          color={option.color}
        >
          {option.display}
        </Option>
      </Row>
    ))}
  </Container>
);
