import styled, { keyframes } from "styled-components";
import { useRef, useState, useEffect } from "react";
import * as shapeSvgs from "../svgs/stars";
import SVG from "react-inlinesvg";
import { renderer } from "../svgs/renderer";

const shapes = [
  shapeSvgs.shape1,
  shapeSvgs.shape2,
  shapeSvgs.shape3,
  shapeSvgs.shape4,
  shapeSvgs.shape5,
  shapeSvgs.shape6,
  shapeSvgs.shape7,
  shapeSvgs.shape8,
  shapeSvgs.shape9,
  shapeSvgs.shape10,
];

const starAnimate = (color: string) => keyframes`
  0% {
    box-shadow: 0px 0px 6px 3px rgba(255, 255, 255, 0.37);
  }
  50% {
    box-shadow: 0px 0px 6px 9px ${color};
  }
  100% {
    box-shadow: 0px 0px 6px 3px rgba(255, 255, 255, 0.37);
  }
`;

const ShapeSpin = styled.div`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation-name: spin;
  animation-duration: 15000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 0;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(20, 20, 20);
  z-index: 1;
`;

const randomNumber = (min: number, max: number) => {
  const r = Math.floor(Math.random() * (max - min) + min);
  return r;
};

const Star = styled.div<{ x: number; y: number; size: number }>`
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  background-color: ${(props) => props.color};
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  z-index: 0;
  //   animation: ${(props) => starAnimate("rgba(255, 255, 255, 0.5)")} 6s ease-in-out infinite;
`;

const randomColor = () =>
  `rgb(${randomNumber(0, 255)}, ${randomNumber(0, 255)}, ${randomNumber(0, 255)})`;

export const Stars = ({ count }: { count: number }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [set, isSet] = useState<boolean>(false);
  const [randomColors, setRandomColors] = useState<
    { x: number; y: number; size: number; color: string }[]
  >(new Array(count).fill(null).map(() => ({ x: 0, y: 0, size: 0, color: randomColor() })));

  useEffect(() => {
    if (containerRef.current?.clientWidth && containerRef.current?.clientHeight && !set) {
      const width = containerRef.current?.clientWidth;
      const height = containerRef.current?.clientHeight;

      setRandomColors(
        randomColors.map(() => ({
          x: randomNumber(0, width),
          y: randomNumber(0, height),
          size: randomNumber(6, 12),
          color: randomColor(),
        }))
      );

      isSet(true);
    }
  }, [containerRef, set, randomColors]);

  const shapeColors = randomColors.slice(10, 20);

  return (
    <Container ref={containerRef}>
      {randomColors.slice(0, 10).map((star) => (
        <Star {...star} />
      ))}
      {shapeColors.map((shape, i) => (
        <ShapeSpin style={{ position: "absolute", top: `${shape.y}px`, left: `${shape.x}px` }}>
          <SVG
            src={renderer({
              colors: ["none", shape.color],
              svg: shapes[i],
              dimensions: { x: 5, y: 5 },
            })}
            height={50}
            width={50}
          />
        </ShapeSpin>
      ))}
    </Container>
  );
};
