//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const crystal = [
    0, 0, 0, 0, 0, 2, 2, 0, 0, 0, 0, 0, 
    0, 0, 5, 0, 4, 2, 2, 4, 0, 0, 0, 0, 
    0, 0, 0, 3, 4, 4, 4, 4, 3, 0, 0, 0, 
    0, 0, 0, 2, 3, 3, 3, 3, 2, 5, 0, 0, 
    0, 0, 0, 2, 1, 3, 2, 2, 2, 0, 0, 0, 
    0, 5, 0, 2, 1, 3, 2, 4, 2, 0, 5, 0, 
    5, 0, 0, 2, 1, 3, 1, 2, 2, 5, 0, 0, 
    0, 5, 0, 2, 2, 1, 3, 1, 2, 0, 0, 0, 
    0, 0, 0, 2, 4, 2, 3, 1, 2, 0, 0, 0, 
    0, 0, 0, 3, 2, 2, 3, 1, 3, 0, 0, 0, 
    0, 0, 5, 0, 3, 3, 3, 3, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 4, 4, 0, 0, 0, 0, 0,
]