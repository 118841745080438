import styled from "styled-components";
import { Dispatch, SetStateAction } from "react";
import { GameStates } from "../../../game-states";
import { attemptRename } from "../../../web3/methods";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";

const Container = styled.div<{ used: boolean }>`
  height: 100px;
  width: 100px;
  @keyframes word-float {
    0% {
      transform: translatey(0px);
      text-shadow: 2px -12px 8px #ffffff;
    }
    50% {
      transform: translatey(-20px);
      text-shadow: 2px 12px 8px #ffffff;
    }
    100% {
      transform: translatey(0px);
      text-shadow: 2px -12px 8px #ffffff;
    }
  }
  animation: word-float 3s ease-in-out infinite;
  display: flex;
  position: relative;
  cursor: ${(props) => (props.used ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.used ? "0.5" : "1")};

  &:hover {
    opacity: 0.7;
  }
`;

export type MWordProps = {
  stagedMGearToRename: {
    mgear: BigNumber;
    name: string;
    id: BigNumber;
  };
  word: string;
  index: number;
  format: 0 | 1;
  setGameState: Dispatch<SetStateAction<number>>;
  gearTypeName: string;
  rarity: number;
  used: boolean;
};

export const MWord = ({
  stagedMGearToRename,
  word,
  index,
  gearTypeName,
  setGameState,
  format,
  used,
}: MWordProps) => {
  const { library } = useWeb3React<Web3Provider>();

  return (
    <Container
      used={used}
      onClick={async () => {
        if (!library) {
          return;
        }
        await attemptRename(library, stagedMGearToRename.id, index, format);
        setGameState(GameStates.AFTER_RENAME);
      }}
    >
      {format ? `${gearTypeName} of ${word}` : `${word} ${gearTypeName}`}
    </Container>
  );
};
