import styled from "styled-components";
import { useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { getMPunksForAccount } from "../../web3/methods";
import { MPunk, MPunkProps } from "./MPunk";
import { TextContainer, Center } from "../TextContainer";

const MPunksContainer = styled.div<{ onClick: () => void }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 100px;
  margin-top: 20px;
`;

export const MPunksControls = ({ setStagedMPunk, setGameState }: Omit<MPunkProps, "tokenId">) => {
  const [mpunks, setMpunks] = useState<Array<{ punkId: number; used: boolean }> | null>(null);
  const { library, account } = useWeb3React<Web3Provider>();

  useEffect(() => {
    const fetchMpunks = async () => {
      try {
        if (library && account) {
          const fetchedMPunks = await getMPunksForAccount(library, account);
          setMpunks(fetchedMPunks);
        }
      } catch (e: any) {
        console.log(e.message);
      }
    };

    fetchMpunks();
  }, [account, library]);

  return mpunks ? (
    <>
      {mpunks.length ? (
        mpunks.map(({ punkId, used }) => (
          <MPunksContainer onClick={() => console.log("hi")}>
            <MPunk {...{ tokenId: punkId, setStagedMPunk, setGameState, used }} renderOnChain />
          </MPunksContainer>
        ))
      ) : (
        <Center>
          <TextContainer>You have no mpunks.</TextContainer>
        </Center>
      )}
    </>
  ) : (
    <Center>
      <TextContainer>Loading your mpunks...</TextContainer>
    </Center>
  );
};
