import styled from "styled-components";
import { useState, useEffect, useMemo } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { BattleProgress } from "./BattleProgress";
import { Creature } from "./Creature";
import { Layout, Area } from "../../../layout";
import { Challenger } from "./Challenger";
import { executeGame } from "../../../utils/game-sim";
import ReactInterval from "react-interval";
import { getHealthFromTurn } from "../../../utils/battle";
import { BigNumber } from "ethers";
import { getGameResult } from "../../../utils/mmonsters";
import { StatValues, getPlayerStats, generateEnemy } from "../../../web3/methods-monsters";
import { hash } from "../../Mine/mine";
import { GameStateChanger } from "../../../utils/types";
import { Stats } from "../../ChangingRoom/Stats";
import { useAddSvg } from "../../../utils/hooks";
import { Svg } from "../../ChangingRoom/Composed";
import { NormalizedMgear } from "../../../web3/methods";
import { getEquippedMGear } from "../../../web3/methods-monsters";
import { guySvg } from "../../svgs";
import { Ellipsis } from "../../Ellipsis";
import { guySvgFromAddress } from "../../svgs";
import { TextContainer } from "../../TextContainer";
import { mgearToSvgWithHandiness, getHandiness } from "../../ChangingRoom/mgear-to-svg";

type BattleExecutionProps = GameStateChanger & {
  nonce: BigNumber;
};

const areaStyle = {
  display: "flex" as "flex",
  flexDirection: "column" as "column",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  height: "100%",
  width: "100%",
  paddingTop: "30px",
};

const Attack = styled.div`
  @keyframes attack {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(20deg);
    }
    50% {
      transform: rotate(20deg);
      transform: translatex(100px);
    }
    66% {
      transform: rotate(0);
      transform: -translatex(20px);
    }
    100% {
      transform: rotate(0);
    }
  }
  animation: attack 1s ease-in-out;
`;

export const BattleExecution = ({ nonce, setGameState }: BattleExecutionProps) => {
  const { library, account } = useWeb3React<Web3Provider>();

  const [turn, setTurn] = useState(0);
  const [stats, setStats] = useState<StatValues | null>(null);
  const [enemy, setEnemy] = useState<StatValues | null>(null);
  const gameExecution = useMemo(() => {
    if (stats && enemy) {
      return executeGame(getGameResult(nonce), stats, enemy);
    }
    return null;
  }, [enemy, nonce, stats]);

  useEffect(() => {
    const load = async () => {
      if (library && account && !stats) {
        const playerStats = await getPlayerStats(library!, account!);
        const enemy = await generateEnemy(library, account, nonce);
        setStats(playerStats);
        setEnemy(enemy);
      }
    };

    load();
  }, [library, account, stats, nonce]);

  const [svgs, setSvgs] = useState<Svg[]>([guySvgFromAddress(BigNumber.from(account))]);
  const [hasEquipped, setHasEquipped] = useState(false);
  const addSvg = useAddSvg(setSvgs);
  const [mgear, setMgear] = useState<NormalizedMgear[] | null>(null);

  useEffect(() => {
    if (!mgear && library && account) {
      const load = async () => {
        const fetchedGear = await getEquippedMGear(library, account);
        setMgear(fetchedGear);
      };
      load();
    }
  }, [mgear, account, library, addSvg]);

  useEffect(() => {
    if (mgear && !hasEquipped) {
      const handiness = getHandiness(mgear.map((e) => e?.mgear || null));
      mgear.forEach((mg) => addSvg(mgearToSvgWithHandiness(mg.mgear, handiness)));
      setHasEquipped(true);
    }
  }, [mgear, addSvg, hasEquipped, setHasEquipped]);

  const advanceTurn = () => {
    if (gameExecution && turn < gameExecution.turns.length) setTurn((t) => t + 1);
  };

  const challengerAnimationTrigger = useMemo(() => {
    if (turn % 2 === 0) return turn;
    return turn - 1;
  }, [turn]);

  const enemyAnimationTrigger = useMemo(() => {
    if (turn % 2 === 1) return turn;
    return turn - 1;
  }, [turn]);

  console.log({ nonce, enemy, stats });

  return gameExecution && enemy && stats && mgear ? (
    <Layout style={{ padding: "50px" }}>
      <ReactInterval callback={advanceTurn} timeout={1000} enabled />
      <Area name="dialogue" style={{ ...areaStyle }}>
        <div key={challengerAnimationTrigger}>
          <Attack>
            <Challenger
              health={stats.vigor}
              damageTaken={
                stats.vigor - getHealthFromTurn(gameExecution.turns, stats, enemy, turn).player
              }
              svgs={svgs}
            />
          </Attack>
        </div>
        <Stats {...stats} hideGearScore />
      </Area>
      <Area name="ethersmith">
        <BattleProgress
          {...{ result: gameExecution.result, turns: gameExecution.turns.slice(0, turn + 1) }}
          finished={turn >= gameExecution.turns.length}
          setGameState={setGameState}
          nonce={nonce}
        />
      </Area>
      <Area name="options" style={areaStyle}>
        <Creature
          animationTrigger={enemyAnimationTrigger}
          seed={nonce}
          health={enemy.vigor}
          damageTaken={
            enemy.vigor - getHealthFromTurn(gameExecution.turns, stats, enemy, turn).enemy
          }
        />
        <Stats {...enemy} hideGearScore />
      </Area>
    </Layout>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <TextContainer>
        Preparing for battle
        <Ellipsis />
      </TextContainer>
    </div>
  );
};
