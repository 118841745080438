import styled from "styled-components";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { Layout, Area } from "../../layout";
import { GameStates } from "../../game-states";
import { BattleExecution } from "./BattleExecution";
import { WaitAtPortal } from "./WaitAtPortal";
import { BattleEquip } from "./BattleEquip";
import { useMine, MiningStatus } from "../Mine";
import { BigNumber } from "ethers";
import { StatValues, getPlayerStats, assertValidGear } from "../../web3/methods-monsters";
import { BattleTalkOptions } from "./TalkOptions";
import { Portal } from "../Portal";
import { TextPane } from "../TextContainer";
import { Backup } from "./Backup";

const noDifficulty = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
const difficulty = "0x000000ffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";
const difficultyTarget = BigNumber.from(difficulty);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
  animation: fade-in 0.8s ease-in-out;
`;

const fadeInStyle = { animation: "fade-in 0.8s ease-in-out" };

type BattleProps = {
  gameState: number;
  setGameState: Dispatch<SetStateAction<number>>;
};

export const Battle = ({ gameState, setGameState }: BattleProps) => {
  const { library, account } = useWeb3React<Web3Provider>();
  const [miningStatus, setMiningStatus] = useState<MiningStatus>(MiningStatus.STOPPED);

  const { found, hashRate, setFound, elapsed } = useMine({
    difficultyTarget,
    miningStatus,
    setMiningStatus,
  });

  const [stats, setStats] = useState<(StatValues & { init: boolean }) | null>(null);
  const [validGear, setValidGear] = useState<boolean | null>(null);

  console.log({ stats });

  useEffect(() => {
    const load = async () => {
      if (library && account && !stats) {
        const playerStats = await getPlayerStats(library!, account!);
        setStats(playerStats);

        const hasValidGear = await assertValidGear(library!, account!, playerStats.init);
        setValidGear(hasValidGear);
      }
    };

    load();
  }, [library, account, stats]);

  if (!library) return <div>oops! no web3 found.</div>;

  const portalOptions = (disableBattle?: boolean) => [
    {
      toGameState: GameStates.BATTLE_EQUIP_ITEMS,
      display: "Equip items.",
    },
    {
      toGameState: GameStates.APPROACH_PORTAL,
      display: "Approach the portal.",
      disabled: disableBattle,
      callback: () => {
        setFound(null);
        setMiningStatus(MiningStatus.WAITING_TO_START);
      },
    },
    {
      toGameState: GameStates.INTRO,
      display: "Return to the Ethersmith.",
    },
  ];

  if (gameState === GameStates.BATTLE_PREPARE || gameState === GameStates.APPROACH_PORTAL) {
    const isWaiting = gameState === GameStates.APPROACH_PORTAL && !found;
    return (
      <FlexContainer>
        {(isWaiting || gameState === GameStates.BATTLE_PREPARE) && (
          <div style={{ width: isWaiting ? "30%" : "15%", transition: "all 1s ease" }}>
            <Portal />
          </div>
        )}
        {gameState === GameStates.BATTLE_PREPARE && (
          <>
            {stats?.vigor === 0 && <div>You need to equip mgear before taking on mfiends.</div>}
            <TextPane>
              There is a wormhole in the far reaches of mineable_space. <br />
              <br />
              If you go close to the portal and begin exuding large amounts of computational energy,
              you may attract an mfiend...
            </TextPane>
            {!stats?.init && (
              <TextPane style={{ color: "yellow" }}>
                You need to equip mgear before taking on mfiends.
              </TextPane>
            )}
            {validGear === false && (
              <TextPane style={{ color: "red" }}>
                You currently have mgear equipped that you do not own. You must unequip before
                fighting mfiends.
              </TextPane>
            )}
            <BattleTalkOptions
              setGameState={setGameState}
              options={portalOptions(!stats?.init || !validGear)}
            />
          </>
        )}
        {gameState === GameStates.APPROACH_PORTAL && (
          <WaitAtPortal
            found={found}
            hashRate={hashRate}
            setGameState={setGameState}
            elapsed={elapsed}
          />
        )}
      </FlexContainer>
    );
  }

  if (gameState === GameStates.APPROACH_PORTAL) {
    return (
      <WaitAtPortal
        found={found}
        hashRate={hashRate}
        setGameState={setGameState}
        elapsed={elapsed}
      />
    );
  }

  if (gameState === GameStates.CALL_ETHERSMITH_FOR_BACKUP) {
    return <Backup gameState={gameState} setGameState={setGameState} />;
  }

  if (gameState === GameStates.BATTLE && found) {
    return <BattleExecution nonce={found} setGameState={setGameState} />;
  }

  if (gameState === GameStates.BATTLE_EQUIP_ITEMS) {
    return <BattleEquip setGameState={setGameState} />;
  }

  return <></>;
};
