import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { getColorsForAddress } from "../../../svgs";
import { BigNumber } from "ethers";
import styled from "styled-components";

const ColorBox = styled.div<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  height: 28px;
  width: 28px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
`;

const Color = ({ bgColor, name }: { bgColor: string; name: string }) => (
  <div style={{ display: "inline-block", fontSize: "12px" }}>
    {name}
    <ColorBox bgColor={bgColor} />
    {bgColor}
  </div>
);

const indexToName = ["", "hair", "eyes", "skin", "sleeves", "body"];

export const AvatarColors = () => {
  const { account } = useWeb3React<Web3Provider>();

  const colors = account ? getColorsForAddress(BigNumber.from(account!)) : [];

  return (
    <Container>
      {colors.map(
        (bgColor, i) => bgColor !== "none" && <Color bgColor={bgColor} name={indexToName[i]} />
      )}
    </Container>
  );
};
