const hair = "%23001c99";
const eyes = "%2334039e";
const skin = "%23ab99d1";
const clothes = "black";
const arms = "%236B2D5C";
const anvil = "%235D5D81";
const hammer = "%2393BEDF";
const ingot = "%23EC9F05";
const ring = "%2329231f";
const ring1 = "%2314E3FA";
const ring2 = "%235DFAFA";
const ring3 = "%23D2FAF7";

export const normalColors = ["none", hair, eyes, skin, clothes, arms, anvil, hammer, ingot, ring];

export const transmutationColors = [
  "none",
  ingot,
  eyes,
  skin,
  clothes,
  arms,
  ring1,
  ring2,
  ring3,
  anvil,
  hammer,
];
