import { useState, useEffect } from "react";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "ethers";
import { renderData } from "../../../web3/methods-monsters";
import SVG from "react-inlinesvg";
import { TextContainer } from "../../TextContainer";
import styled from "styled-components";

type MonsterProps = { nonce: BigNumber; animationTrigger?: number };

const rarityOutOf32 = {
  abyssal: 1,
  umbral: 2,
  luminous: 2,
  plasmic: 4,
  fluid: 4,
  organic: 4,
  vibrant: 8,
  prototypical: 7,
};

const getRarityScore = () => {};

const Container = styled.div`
  width: 100%%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @keyframes creature-float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-20px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  animation: creature-float 3s ease-in-out infinite;
`;

const ZoomIn = styled.div`
  width: 100%%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @keyframes zoom-in {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  animation: zoom-in 1.2s ease-in-out;
`;

const Shake = styled.div`
  width: 100%%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(0px) rotate(0deg);
    }
  }
  animation: shake 1.4s ease-in-out;
`;

const AttackEnemy = styled.div`
  @keyframes attack-enemy {
    0% {
      transform: rotate(0);
    }
    25% {
      transform: rotate(-20deg);
    }
    50% {
      transform: rotate(-20deg);
      transform: translatex(-100px);
    }
    66% {
      transform: rotate(0);
      transform: translatex(-20px);
    }
    100% {
      transform: rotate(0);
    }
  }
  animation: attack-enemy 1s ease-in-out;
`;

export const Monster = ({ nonce, animationTrigger }: MonsterProps) => {
  const { library, account } = useWeb3React<Web3Provider>();
  const [render, setRender] = useState<{
    svg: string;
    affinity: string;
    runed: boolean;
    winged: boolean;
  } | null>(null);

  useEffect(() => {
    const load = async () => {
      console.log("loading");
      const rendered = await renderData(library!, account!, nonce);
      setRender(rendered);
    };
    if (!render) load();
  }, [render, account, library, nonce]);

  console.log({ render });

  return render ? (
    <ZoomIn>
      <Shake>
        <AttackEnemy key={animationTrigger}>
          <Container>
            <div style={{ flexGrow: 1, minWidth: "300px" }}>
              <SVG src={render.svg} style={{ height: "100%" }} />
            </div>
            <TextContainer>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div>Affinity: {render.affinity}</div>
                {render.winged && <div style={{ color: "yellow" }}>RARE FORM: WINGED</div>}
                {render.runed && <div style={{ color: "yellow" }}>RARE FORM: RUNED</div>}
              </div>
            </TextContainer>
          </Container>
        </AttackEnemy>
      </Shake>
    </ZoomIn>
  ) : (
    <Container>
      <TextContainer>A challenger approaches...</TextContainer>
    </Container>
  );
};
