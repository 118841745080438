export * from "./chainmail.svg";
export * from "./platebody.svg";
export * from "./robes.svg";
export * from "./robes-main.svg";
export * from "./robes-off.svg";
export * from "./robes-neither.svg";
export * from "./shirt.svg";
export * from "./straps.svg";

export const z = 2;
