import styled from "styled-components";
import { Control } from "./Control";

export const skinColors = [
  "#40322a",
  "#917f74",
  "#a19382",
  "#705144",
  "#f5c098",
  "#d6be6d",
  "#f5b48e",
  "#abcdf7",
  "#edb951",
  "#9874ad",
  "#cc927c",
  "#E5F4E3",
  "#BD4C50",
  "#BCD0C7",
  "#2F131E",
  "#F3DFBF",
  "#ffe033",
];
export const hairColors = [
  "#bd992d",
  "#383323",
  "#2f3566",
  "#592e3c",
  "#1a1813",
  "#4ecf88",
  "#780b33",
  "#003F91",
  "#2a451d",
  "#ffe175",
  "#b697e8",
  "#ff1900",
  "#B2D3A8",
  "#E88873",
  "#6D326D",
  "#FF715B",
  "#90A959",
  "#F9DC5C",
  "#3185FC",
  "#E84855",
  "#EFBCD5",
];
export const eyeColors = [
  "#18d94b",
  "#1845d9",
  "#8518d9",
  "#d91865",
  "#ffef14",
  "#3cc794",
  "#f7582d",
  "#211b15",
  "#ff00d0",
  "#472836",
  "#9AD2CB",
  "#BD4C50",
];
export const clothingColors = [
  "#1a1916",
  "#d9d4c3",
  "#264599",
  "#5c4153",
  "#9c416e",
  "#15361e",
  "#453123",
  "#f5771d",
  "#48d43b",
  "#eb73c5",
  "#471323",
  "#592941",
  "#498467",
  "#A37774",
  "#BCED09",
  "#4CFACC",
  "#F38D68",
  "#70D6FF",
  "#FF9770",
  "#FF70A6",
  "#FFC533",
  "#E9FF70",
];
export const armColors = [
  "#782071",
  "#bf7934",
  "#450a42",
  "#91cfb7",
  "#7f8746",
  "#de1907",
  "#ffd900",
  "#1933b5",
  "#2f344d",
  "#CEB992",
  "#73937E",
  "#585563",
  "#5B2E48",
  "#BBB09B",
];

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 20px;
`;

type CharacterControlsProps = {
  updateGuy: (index: number) => (color: string) => void;
};

export const CharacterControls = ({ updateGuy }: CharacterControlsProps) => {
  return (
    <Container>
      <Control name={"Hair"} values={hairColors} updateGuy={updateGuy(1)} />
      <Control name={"Eyes"} values={eyeColors} updateGuy={updateGuy(2)} />
      <Control name={"Skin"} values={skinColors} updateGuy={updateGuy(3)} />
      <Control name={"Arms"} values={armColors} updateGuy={updateGuy(4)} />
      <Control name={"Clothing"} values={clothingColors} updateGuy={updateGuy(5)} />
    </Container>
  );
};
