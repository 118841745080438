import * as mainhand from "../svgs/changing-room/gear/by-slot/mainhand";
import * as offhand from "../svgs/changing-room/gear/by-slot/offhand";
import * as body from "../svgs/changing-room/gear/by-slot/body";
import * as head from "../svgs/changing-room/gear/by-slot/head";
import * as legs from "../svgs/changing-room/gear/by-slot/legs";
import * as neck from "../svgs/changing-room/gear/by-slot/neck";
import * as hands from "../svgs/changing-room/gear/by-slot/hands";
import * as feet from "../svgs/changing-room/gear/by-slot/feet";
import * as back from "../svgs/changing-room/gear/by-slot/back";
import {
  getMGearTypeName,
  getRarityIndex,
  getIsAugmented,
  getColorsFromMgear,
  MGearTypes,
} from "../../utils/mgear";
import { Svg } from "./Composed";
import { BigNumber } from "ethers";

import { z as Offhand } from "../svgs/changing-room/gear/by-slot/offhand";
import { z as Mainhand } from "../svgs/changing-room/gear/by-slot/mainhand";

export enum MgearGroup {
  RUIN,
  GUARD,
  VIGOR,
  CELERITY,
}

export const mgearToSvgMap: {
  [key in MGearTypes]: {
    svg: Svg["svg"];
    z: Svg["z"];
    noOffhand?: Svg["svg"];
    noMainhand?: Svg["svg"];
    neither?: Svg["svg"];
    id?: string;
    group: MgearGroup;
  };
} = {
  dagger: {
    svg: mainhand.dagger,
    z: mainhand.z,
    group: MgearGroup.RUIN,
  },
  bow: {
    svg: mainhand.bow,
    z: mainhand.z,
    group: MgearGroup.RUIN,
  },
  staff: {
    svg: mainhand.staff,
    z: mainhand.z,
    group: MgearGroup.RUIN,
  },
  scimitar: {
    svg: mainhand.scimitar,
    z: mainhand.z,
    group: MgearGroup.RUIN,
  },
  bowstaff: {
    svg: mainhand.bowstaff,
    z: mainhand.z,
    group: MgearGroup.RUIN,
  },
  katana: {
    svg: mainhand.katana,
    z: mainhand.z,
    group: MgearGroup.RUIN,
    id: "katana",
  },
  axe: {
    svg: mainhand.axe,
    z: mainhand.z,
    group: MgearGroup.RUIN,
  },
  muramasa: {
    svg: mainhand.muramasa,
    z: mainhand.z,
    group: MgearGroup.RUIN,
  },
  shirt: {
    svg: body.shirt,
    z: body.z,
    group: MgearGroup.GUARD,
  },
  robes: {
    svg: body.robes,
    z: body.z,
    noMainhand: body.robesNoMainHand,
    noOffhand: body.robesNoOffhand,
    neither: body.robesNeither,
    id: "robes",
    group: MgearGroup.GUARD,
  },
  chainmail: {
    svg: body.chainmail,
    z: body.z,
    group: MgearGroup.GUARD,
  },
  straps: {
    svg: body.straps,
    z: body.z,
    group: MgearGroup.GUARD,
  },
  platebody: {
    svg: body.platebody,
    z: body.z,
    group: MgearGroup.GUARD,
  },
  buckler: {
    svg: offhand.buckler,
    z: offhand.z,
    group: MgearGroup.GUARD,
  },
  kiteshield: {
    svg: offhand.kiteshield,
    z: offhand.z,
    group: MgearGroup.GUARD,
  },
  trinket: {
    svg: offhand.trinket,
    z: offhand.z,
    group: MgearGroup.VIGOR,
    id: "trinket",
  },
  orb: {
    svg: offhand.orb,
    z: offhand.z,
    group: MgearGroup.VIGOR,
  },
  crystal: {
    svg: offhand.crystal,
    z: offhand.z,
    group: MgearGroup.VIGOR,
  },
  artifact: {
    svg: offhand.artifact,
    z: offhand.z,
    group: MgearGroup.VIGOR,
  },
  helmet: {
    svg: head.helmet,
    z: head.z,
    group: MgearGroup.GUARD,
  },
  specs: {
    svg: head.specs,
    z: head.z,
    group: MgearGroup.CELERITY,
  },
  crown: {
    svg: head.crown,
    z: head.z,
    group: MgearGroup.VIGOR,
  },
  pants: {
    svg: legs.pants,
    z: legs.z,
    group: MgearGroup.GUARD,
  },
  amulet: {
    svg: neck.amulet,
    z: neck.z,
    group: MgearGroup.VIGOR,
  },
  heart: {
    svg: neck.heart,
    z: neck.z,
    group: MgearGroup.VIGOR,
  },
  gauntlets: {
    svg: hands.gauntlets,
    z: hands.z,
    noMainhand: hands.gauntletsNoMainHand,
    noOffhand: hands.gauntletsNoOffhand,
    neither: hands.gauntletsNeither,
    id: "gauntlets",
    group: MgearGroup.VIGOR,
  },
  ring: {
    svg: hands.ring,
    z: hands.z,
    noOffhand: hands.ringNoOffhand,
    id: "ring",
    group: MgearGroup.VIGOR,
  },
  boots: {
    svg: feet.boots,
    z: feet.z,
    group: MgearGroup.CELERITY,
    id: "boots",
  },
  sandals: {
    svg: feet.sandals,
    z: feet.z,
    group: MgearGroup.CELERITY,
  },
  cape: {
    svg: back.cape,
    z: back.z,
    group: MgearGroup.CELERITY,
  },
  hat: {
    svg: head.hat,
    z: head.z,
    group: MgearGroup.CELERITY,
  },
  wings: {
    svg: back.wings,
    z: back.z,
    group: MgearGroup.CELERITY,
  },
};

export const getHandiness = (mgears: Array<BigNumber | null>) => {
  const hasMainhand = !!mgears?.some((mgear) =>
    mgear ? mgearToSvgMap[getMGearTypeName(mgear)].z === Mainhand : false
  );
  const hasOffhand = !!mgears?.some((mgear) =>
    mgear ? mgearToSvgMap[getMGearTypeName(mgear)].z === Offhand : false
  );

  return { hasMainhand, hasOffhand };
};

export const mgearToSvg = (mgear: BigNumber, svgs?: Svg[]): Svg => {
  const hasMainhand = !!svgs?.some((a) => a.z === Mainhand);
  const hasOffhand = !!svgs?.some((a) => a.z === Offhand);

  const { svg, z, noMainhand, noOffhand, neither, group, id } =
    mgearToSvgMap[getMGearTypeName(mgear)];

  let svgToAdd = svg;

  if (!hasMainhand) {
    if (!hasOffhand) {
      if (neither) {
        svgToAdd = neither;
      }
    } else {
      if (noMainhand) {
        svgToAdd = noMainhand;
      }
    }
  }

  if (!hasOffhand) {
    if (!hasMainhand) {
      if (neither) {
        svgToAdd = neither;
      }
    } else {
      if (noOffhand) {
        svgToAdd = noOffhand;
      }
    }
  }

  const augmented = getIsAugmented(mgear);
  const rarity = getRarityIndex(mgear);
  const colors = getColorsFromMgear(mgear);

  return {
    augmented,
    svg: svgToAdd,
    z,
    rarity,
    colors,
    mgear,
    group,
    id,
  };
};

export type Handiness = { hasMainhand: boolean; hasOffhand: boolean };

export const mgearToSvgWithHandiness = (mgear: BigNumber, handiness: Handiness): Svg => {
  const { hasMainhand, hasOffhand } = handiness;

  const { svg, z, noMainhand, noOffhand, neither, id, group } =
    mgearToSvgMap[getMGearTypeName(mgear)];

  let svgToAdd = svg;

  if (!hasMainhand) {
    if (!hasOffhand) {
      if (neither) {
        console.log({ id, handiness });
        svgToAdd = neither;
      }
    } else {
      if (noMainhand) {
        svgToAdd = noMainhand;
      }
    }
  }

  if (!hasOffhand) {
    if (!hasMainhand) {
      if (neither) {
        svgToAdd = neither;
      } else {
        if (noOffhand) svgToAdd = noOffhand;
      }
    } else {
      if (noOffhand) {
        svgToAdd = noOffhand;
      }
    }
  }

  const augmented = getIsAugmented(mgear);
  const rarity = getRarityIndex(mgear);
  const colors = getColorsFromMgear(mgear);

  return {
    augmented,
    svg: svgToAdd,
    z,
    rarity,
    colors,
    mgear,
    id,
    group,
  };
};
