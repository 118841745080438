import styled from "styled-components";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const NameContainer = styled.div`
  width: 100px;
`;

const StyledButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

type ControlProps = {
  name: string;
  values: string[];
  updateGuy: (color: string) => void;
};

export const Control = ({ values, name, updateGuy }: ControlProps) => {
  const [index, setIndex] = useState(0);
  return (
    <Container key={name}>
      <NameContainer>{name}</NameContainer>
      <StyledButton
        onClick={() => {
          if (index === 0) {
            updateGuy(values[values.length - 1]);
            setIndex(values.length - 1);
          } else {
            updateGuy(values[index - 1]);
            setIndex((i) => i - 1);
          }
        }}
      >{`<`}</StyledButton>
      <div>{values[index]}</div>
      <StyledButton
        onClick={() => {
          if (index === values.length - 1) {
            updateGuy(values[0]);
            setIndex(0);
          } else {
            updateGuy(values[index + 1]);
            setIndex((i) => i + 1);
          }
        }}
      >{`>`}</StyledButton>
    </Container>
  );
};
