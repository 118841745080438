type SvgProps = { colors: string[]; svg: number[]; dimensions: { x: number; y: number } };

const SVG_HEADER = (dimensions: { x: number; y: number }) =>
  `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 ${dimensions.x} ${dimensions.y}">`;
const SVG_FOOTER = "</svg>";

export const renderer = ({ colors, svg, dimensions }: SvgProps) => {
  const svgElements = [];
  svgElements.push(SVG_HEADER(dimensions));

  for (let y = 0; y < dimensions.y; y++) {
    for (let x = 0; x < dimensions.x; x++) {
      const p = y * dimensions.x + x;
      const pixel = svg[p];
      if (pixel > 0) {
        const color = colors[pixel];
        svgElements.push(
          `<rect x="${x}" y="${y}" width="1" height="1" shape-rendering="crispEdges" fill="${color}" />`
        );
      }
    }
  }

  svgElements.push(SVG_FOOTER);
  return svgElements.join("");
};
