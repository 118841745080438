import { BigNumber } from "ethers";
import styled from "styled-components";
import { MGear } from "../MGear";
import {
  getStats,
  getBonusMod,
  rarityIndexToRarityName,
  rarityIndexToColor,
  getAdjustedRarity,
  getIsAugmented,
  getMGearTypeName,
  encodeMGear,
  getGearQuality,
} from "../../utils/mgear";

type MGearProps = { mgear: BigNumber; rarityIndex: number; isMpunk?: boolean };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MGearWithStats = ({ mgear, rarityIndex }: MGearProps) => {
  const rarity = getAdjustedRarity(mgear, rarityIndex);
  const stats = getStats(mgear, rarity);
  const statKeys = Object.keys(stats);

  return (
    <Container>
      {/* if the adjusted rarity is different from the mined rarity, we got a bonus */}
      {rarity !== rarityIndex && (
        <div style={{ color: rarityIndexToColor[rarity] }}>
          RARITY BONUS!!! {rarityIndexToRarityName[rarity]}
        </div>
      )}
      {getBonusMod(mgear) && <div>BONUS GEAR ROLL</div>}
      {getIsAugmented(mgear) && <div>++AUGMENTED++</div>}
      <MGear mgear={mgear} renderOnChain rarityIndex={rarityIndex} />
      {rarityIndexToRarityName[rarity]} {getMGearTypeName(encodeMGear(mgear, rarity))}
      <div>
        GEAR QUALITY:
        {getGearQuality(
          rarity,
          Object.values(stats).reduce((acc, curr) => acc + curr)
        )}
      </div>
      {statKeys.map((stat) => (
        <div>
          {stat}: +{stats[stat]}
        </div>
      ))}
      {statKeys.length === 0 && <div>No stats.</div>}
    </Container>
  );
};
