//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const hat = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 3, 3, 0, 
    0, 0, 5, 0, 2, 2, 2, 2, 3, 3, 0, 0, 
    0, 0, 0, 5, 2, 1, 1, 2, 3, 0, 0, 0, 
    0, 0, 2, 2, 2, 1, 1, 2, 2, 2, 2, 0, 
    0, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
]
