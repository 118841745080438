import { useCallback, Dispatch, SetStateAction } from "react";
import { Svg } from "../components/ChangingRoom/Composed";
import { z as Offhand } from "../components/svgs/changing-room/gear/by-slot/offhand";
import { z as Mainhand } from "../components/svgs/changing-room/gear/by-slot/mainhand";
import { guyBoth, guyOnlyMain, guyOnlyOff, guyNoGear } from "../components/svgs/changing-room/guy";
import { Handiness } from "../components/ChangingRoom/mgear-to-svg";
import { mgearToSvgWithHandiness } from "../components/ChangingRoom/mgear-to-svg";

export const useAddSvg = (setSvgs: Dispatch<SetStateAction<Svg[]>>) =>
  useCallback(
    (svg: Svg, handiness?: Handiness) => {
      setSvgs((prevSvgs: Svg[]) => {
        const isSvgTheSame = (svg1: Svg, svg2: Svg) =>
          svg1.z === svg2.z && svg1.svg.join("") === svg2.svg.join("");

        let processed = [...prevSvgs];
        const toUnequip = processed.some((s) => isSvgTheSame(s, svg));

        if (toUnequip) {
          processed = processed.filter((s) => !isSvgTheSame(s, svg));
        } else {
          // remove same slot if there
          processed = processed.filter((s) => {
            if (!s.group) return true;
            return s.group !== svg.group;
          });

          // add our new svg
          processed = [...processed, svg];
        }

        // infer handiness from the new setup
        let hasMainhand = !!processed?.some((a) => a.z === Mainhand);
        let hasOffhand = !!processed?.some((a) => a.z === Offhand);

        if (handiness) {
          hasMainhand = handiness.hasMainhand;
          hasOffhand = handiness.hasOffhand;
        }

        console.log({ hasMainhand, hasOffhand });
        // change main guy
        if (hasMainhand) {
          if (hasOffhand) processed[0] = { ...processed[0], svg: guyBoth };
          else processed[0] = { ...processed[0], svg: guyOnlyMain };
        } else if (hasOffhand) {
          processed[0] = { ...processed[0], svg: guyOnlyOff };
        } else {
          processed[0] = { ...processed[0], svg: guyNoGear };
        }

        // let's go back and make sure all the svg handiness is correct
        processed = processed.map((svg) => {
          if (["robes", "gauntlets", "ring"].includes(svg?.id || "")) {
            return mgearToSvgWithHandiness(svg.mgear, { hasMainhand, hasOffhand });
          }
          return svg;
        });

        console.log({ processed });

        return processed;
      });
    },
    [setSvgs]
  );
