//1 = light blue, 2 = blue, 3 = yellow, 4 = red

export const boots = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 5, 5, 5, 5, 0, 
    0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 
    0, 0, 0, 0, 0, 5, 5, 1, 1, 1, 1, 0, 
    0, 0, 0, 0, 5, 4, 4, 4, 2, 2, 3, 0, 
    0, 0, 0, 4, 4, 2, 4, 4, 2, 2, 3, 0, 
    0, 0, 4, 2, 2, 2, 2, 2, 2, 2, 3, 0, 
    0, 0, 3, 3, 3, 3, 3, 3, 3, 3, 3, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
]