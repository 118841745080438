import { useState } from "react";
import ReactInterval from "react-interval";
import styled, { keyframes } from "styled-components";
import { Portal1, Portal2, Portal3, Portal4 } from "../svgs/portal";

enum PortalState {
  TWELVE,
  NINE,
  SIX,
  THREE,
}

const Container = styled.div`
  display: flex;
  animation-name: spin;
  animation-duration: 7000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
`;

const auraAnimate = (color: string) => keyframes`
  0% {
    box-shadow: 0px 0px 30px 20px rgba(200, 200, 200, 0.02);
    background-color: rgba(200, 200, 200, 0.02);
  }
  50% {
    box-shadow: 0px 0px 30px 30px ${color};
    background-color: ${color};
  }
  100% {
    box-shadow: 0px 0px 30px 20px rgba(200, 200, 200, 0.02);
    background-color: rgba(200, 200, 200, 0.02);
  }
`;

const Full = styled.div`
  height: 100%;
  width: 100%;
`;

const Aura = styled.div<{ color: string }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${(props) => auraAnimate(props.color)} 3s ease-in-out infinite;
`;

export const Portal = ({ style }: any) => {
  const [portalState, setPortalState] = useState<PortalState>(PortalState.TWELVE);

  return (
    <Container style={style}>
      <ReactInterval
        timeout={200}
        enabled
        callback={() => {
          setPortalState((value: PortalState) => {
            if (value === PortalState.TWELVE) return PortalState.NINE;
            if (value === PortalState.NINE) return PortalState.SIX;
            if (value === PortalState.SIX) return PortalState.THREE;
            return PortalState.TWELVE;
          });
        }}
      />
      <Full>
        {portalState === PortalState.TWELVE && <Portal1 />}
        {portalState === PortalState.NINE && <Portal3 />}
        {portalState === PortalState.SIX && <Portal2 />}
        {portalState === PortalState.THREE && <Portal4 />}
      </Full>
    </Container>
  );
};
